import React, { PropsWithChildren, ReactNode, useRef, useState } from 'react';
import { OverlayTrigger, OverlayTriggerProps, Popover } from 'react-bootstrap';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import styles from './info-popover.module.scss';

interface IInfoPopoverProps extends PropsWithChildren {
  triggerComponent?: React.JSX.Element;
}

type Props = IInfoPopoverProps & Partial<OverlayTriggerProps>;

export const InfoPopover = ({
  children,
  triggerComponent,
  ...props
}: Props) => {
  const [show, setShow] = useState(false);
  const handleOnMouseEnter = () => {
    setShow(true);
  };
  const handleOnMouseLeave = () => {
    setShow(false);
  };
  const ref = useRef(null);
  return (
    <span onClick={(e) => e.stopPropagation()}>
      <OverlayTrigger
        show={show}
        overlay={
          <Popover
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          >
            <Popover.Body onClick={handleOnMouseLeave}>{children}</Popover.Body>
          </Popover>
        }
        {...props}
      >
        <span
          ref={ref}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        >
          {triggerComponent ? triggerComponent : <AiOutlineInfoCircle />}
        </span>
      </OverlayTrigger>
    </span>
  );
};
