import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { install as installResizeObserver } from 'resize-observer';
import './index.scss';
import { Layout } from './components/layout';
import CarDetail from './pages/car-detail';
import ErrorPage from './pages/error-page';
import Model from './pages/model';
import Models from './pages/models';
import reportWebVitals from './reportWebVitals';

if (!window.ResizeObserver) installResizeObserver();

const router = createBrowserRouter(
  [
    {
      element: <Layout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: '',
          element: <Models />,
        },
        {
          path: ':modelCode',
          element: <Model />,
        },
        {
          path: ':modelCode/:vin',
          element: <CarDetail />,
        },
      ],
    },
  ],
  {
    basename: window.location.pathname.match(/^(\/dealer\/\d+)/)
      ? window.location.pathname.replace(/^(\/dealer\/\d+)\/?(.*)/, '$1')
      : '/',
  }
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
