import { Accordion, AccordionProps } from 'react-bootstrap';
import styles from './info.module.scss';

interface IInfoProps {
  title?: string;
  children: JSX.Element;
}

type Props = IInfoProps & AccordionProps;

export function Info({
  title = 'Інформація',
  children,
  className,
  ...props
}: Props) {
  return (
    <Accordion
      flush
      className={[styles.wrapper, className].join(' ')}
      defaultActiveKey="0"
      {...props}
    >
      <Accordion.Item eventKey="0">
        <Accordion.Header className={styles.header}>
          <span className={styles.headerTitle}>{title}</span>
        </Accordion.Header>
        <Accordion.Body className={styles.body}>
          {children}
          <div about=""></div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
