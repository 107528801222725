import { Carousel } from 'react-bootstrap';
import { Link, LinkProps } from 'react-router-dom';
import { Button } from './button';

interface IBannerProps {
  data: {
    img: string;
    links: { title: string; linkProps: LinkProps }[];
  }[];
}

export function Banner({ data }: IBannerProps) {
  const linksList = Array.from(
    new Map(
      data
        .map(({ links }) => links)
        .flat()
        .map((l) => [l.title.trim(), l])
    ).values()
  );
  return (
    <>
      <Carousel className="p-0" controls={false}>
        {data.map(({ img, links }, i) => {
          return (
            <Carousel.Item key={i}>
              <div className="d-block d-md-none position-relative">
                <div
                  style={{
                    background: `url(${img})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    minHeight: 200,
                    width: '100%',
                  }}
                ></div>
                {/* {!!links.length && (
                <Carousel.Caption
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    gap: 10,
                    position: 'absolute',
                    top: 20,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    background: '#ffffffa4',
                    padding: 20,
                    width: 'max-content',
                    height: 'min-content',
                    maxWidth: '90%',
                  }}
                >
                  {links.map((l, i) => (
                    <Link key={i + l.title} to={l.url}>
                      <Button children={l.title} />
                    </Link>
                  ))}
                </Carousel.Caption>
              )} */}
              </div>
              <div className="d-none d-md-block position-relative">
                <div
                  style={{
                    background: `url(${img})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    height: 350,
                    width: '100%',
                  }}
                ></div>
                {!!links.length && (
                  <Carousel.Caption
                    style={{
                      display: 'flex',
                      gap: 10,
                      position: 'absolute',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      left: 20,
                      height: 'min-content',
                      width: 'min-content',
                      background: '#ffffffa4',
                      padding: 20,
                    }}
                  >
                    {links.map((l, i) => (
                      <Link key={i + l.title} {...l.linkProps}>
                        <Button children={l.title} />
                      </Link>
                    ))}
                  </Carousel.Caption>
                )}
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
      <div
        className="d-flex d-md-none"
        style={{
          backgroundColor: '#000',
          overflowY: 'auto',
          width: '100%',
          // height: 47,
        }}
      >
        {linksList.map(({ title, linkProps }, i) => {
          return (
            <Link key={i + title} {...linkProps}>
              <Button key={i} variant="primary" style={{ fontSize: 12 }}>
                {title}
              </Button>
            </Link>
          );
        })}
      </div>
    </>
  );
}
