import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { checkDealerSite } from '../helpers/check-dealer-site';
import { Container } from 'react-bootstrap';
import { ScrollTop } from './scroll-top';

export function Layout() {
  const location = useLocation();
  const { isDealerSite } = checkDealerSite();

  useEffect(() => {
    if (window.self !== window.top) {
      pushToParentPage({
        type: 'scrollTopIframe',
      });
      return;
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]);

  return (
    <Container fluid style={{ paddingBottom: 60 }}>
      <Outlet />
      <ScrollTop />
    </Container>
  );
}
