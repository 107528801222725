import { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';

interface IMetaProps {
  title?: string;
  description?: string;
}

export const Meta = ({
  title = 'Онлайн склад нових автомобілів Renault',
  description = '',
  children,
}: PropsWithChildren<IMetaProps>) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <html lang="uk" />
      {children}
    </Helmet>
  );
};
