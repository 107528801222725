import { EModelCode } from './types';

export const SedreStatus = {
  inProduction: ['100', '200', '300', '400'],
  onTheWay: ['500', '520', '530', '550'],
  importCenter: ['600'],
  warehouse: ['700', '800'],
  inUkraine: ['600', '700', '800'],
};

export const FakeBir = {
  inProduction: '11111111',
  onTheWay: '11111112',
  importCenter: '11111113',
};

export const FakeBirs = ['11111111', '11111112', '11111113'];

type CarImgInfo = Record<
  keyof typeof EModelCode,
  {
    img: string;
    ext: {
      imgList?: string[];
      byOptions?: {
        base?: string;
        list: { img: string; code: string }[];
      }[];
      colors?: Record<string, { base: string; list: string[] }>;
      filters?: {
        eqp?: string[];
        versions?: string[];
        colors?: string[];
      };
    }[];
  }
>;

export const CarImages: CarImgInfo = {
  JD1: {
    img: 'duster.jpg',
    ext: [
      {
        filters: {
          eqp: ['Life', 'Life+'],
        },
        colors: {
          '369': { base: 'Life-369.jpg', list: ['Life-369-1.jpg'] },
          '676': { base: 'Life-676.jpg', list: ['Life-676-1.jpg'] },
          ENZ: { base: 'Life-ENZ.jpg', list: ['Life-ENZ-1.jpg'] },
          KNA: { base: 'Life-KNA.jpg', list: ['Life-KNA-1.jpg'] },
          KPW: { base: 'Life-KPW.jpg', list: ['Life-KPW-1.jpg'] },
        },
        imgList: ['Duster_E1_driving_wheel.jpg', 'Duster_E1_seats.jpg'],
      },
      {
        filters: {
          eqp: ['Life', 'Life+'],
          versions: ['N1G2 M1M 5C', 'N1G2 ABM 6RS'], // E1 4x2
        },
        imgList: ['Duster_E1_4x2_interior.jpg'],
      },
      {
        filters: {
          eqp: ['Life', 'Life+'],
          versions: ['N1G4 M1M 5C', 'N1G4 ABM 6RS'], // E1 4x
        },
        imgList: ['Duster_E1_4x4_interior.jpg'],
      },
      {
        filters: {
          eqp: ['Zen', 'Zen+'], // base
        },
        colors: {
          '369': { base: 'Zen-369.jpg', list: ['Zen-369-1.jpg'] },
          '676': { base: 'Zen-676.jpg', list: ['Zen-676-1.jpg'] },
          ENZ: { base: 'Zen-ENZ.jpg', list: ['Zen-ENZ-1.jpg'] },
          KNA: { base: 'Zen-KNA.jpg', list: ['Zen-KNA-1.jpg'] },
          KPW: { base: 'Zen-KPW.jpg', list: ['Zen-KPW-1.jpg'] },
        },
        imgList: [
          'Duster_E2_multimedia.jpg',
          'Duster_E2_seats.jpg',
          'Duster_E2.jpg',
        ],
        byOptions: [
          {
            list: [{ img: 'Duster_E2_with_PCV46.jpg', code: 'PCV46' }],
          },
        ],
      },
      {
        filters: {
          eqp: ['Zen', 'Zen+'], // base
          versions: ['N2G2 M1M 5C', 'N2G2 ABM 6RS', 'N2G2 ABA 6RS'], // E2 4x2
        },
        imgList: ['Duster_E2_4x2.jpg'],
      },
      {
        filters: {
          eqp: ['Zen', 'Zen+'], // base
          versions: ['N2 4 ABM 6RS', 'N2G4 M1M 5C', 'N2G4 ABM 6RS'], // E2 4x4
        },
        imgList: ['Duster_E2_4x4_interior.jpg'],
      },
    ],
  },
  CP1: {
    img: 'captur.jpg',
    ext: [
      {
        filters: { eqp: ['Zen', 'Zen+'] },
        colors: {
          GNE: { base: 'Zen-GNE.jpg', list: ['Zen-GNE-1.jpg'] },
          KNG: { base: 'Zen-KNG.jpg', list: ['Zen-KNG-1.jpg'] },
          KQA: { base: 'Zen-KQA.jpg', list: ['Zen-KQA-1.jpg'] },
          NNP: { base: 'Zen-NNP.jpg', list: ['Zen-NNP-1.jpg'] },
          QNC: { base: 'Zen-QNC.jpg', list: ['Zen-QNC-1.jpg'] },
          RPE: { base: 'Zen-RPE.jpg', list: ['Zen-RPE-1.jpg'] },
          RQH: { base: 'Zen-RQH.jpg', list: ['Zen-RQH-1.jpg'] },
        },
        imgList: ['Captur_E2.jpg', 'Captur_E2_&_E3.jpg', 'Captur_E2_disks.jpg'],
      },
      {
        filters: { eqp: ['Intense'] },
        colors: {
          XNK: { base: 'Intense-XNK.jpg', list: ['Intense-XNK-1.jpg'] },
          XPA: { base: 'Intense-XPA.jpg', list: ['Intense-XPA-1.jpg'] },
          XPE: { base: 'Intense-XPE.jpg', list: ['Intense-XPE-1.jpg'] },
          XUI: { base: 'Intense-XUI.jpg', list: ['Intense-XUI-1.jpg'] },
          YNM: { base: 'Intense-YNM.jpg', list: ['Intense-YNM-1.jpg'] },
          YNP: { base: 'Intense-YNP.jpg', list: ['Intense-YNP-1.jpg'] },
          YNT: { base: 'Intense-YNT.jpg', list: ['Intense-YNT-1.jpg'] },
          YNV: { base: 'Intense-YNV.jpg', list: ['Intense-YNV-1.jpg'] },
          YNW: { base: 'Intense-YNW.jpg', list: ['Intense-YNW-1.jpg'] },
          YPB: { base: 'Intense-YPB.jpg', list: ['Intense-YPB-1.jpg'] },
          YPE: { base: 'Intense-YPE.jpg', list: ['Intense-YPE-1.jpg'] },
          YUC: { base: 'Intense-YUC.jpg', list: ['Intense-YUC-1.jpg'] },
          YUD: { base: 'Intense-YUD.jpg', list: ['Intense-YUD-1.jpg'] },
        },
        imgList: [
          'Captur_E2_&_E3.jpg',
          'Captur_E3.jpg',
          'Captur_E3_.jpg',
          'Captur_Е3_disks.jpg',
        ],
      },
    ],
  },
  STW: { img: 'STW.jpg', ext: [] },
  ZGH: {
    img: 'koleos.jpg',
    ext: [
      {
        filters: {
          eqp: ['Zen', 'Zen+'],
        },
        colors: {
          GNG: { base: 'Koleos_GNG.jpg', list: ['Koleos_GNG-1.jpg'] },
          GXA: { base: 'Koleos_GXA.jpg', list: ['Koleos_GXA-1.jpg'] },
          KAD: { base: 'Koleos_KAD.jpg', list: ['Koleos_KAD-1.jpg'] },
          NPN: { base: 'Koleos_NPN.jpg', list: ['Koleos_NPN-1.jpg'] },
          QXD: { base: 'Koleos_QXD.jpg', list: ['Koleos_QXD-1.jpg'] },
        },
        imgList: ['E2_&_E3_disks.jpg', 'E2_interior.jpg', 'E2_seats.jpg'],
      },
      {
        filters: {
          eqp: ['Intense'],
        },
        colors: {
          GNG: { base: 'Koleos_GNG.jpg', list: ['Koleos_GNG-1.jpg'] },
          GXA: { base: 'Koleos_GXA.jpg', list: ['Koleos_GXA-1.jpg'] },
          KAD: { base: 'Koleos_KAD.jpg', list: ['Koleos_KAD-1.jpg'] },
          NPN: { base: 'Koleos_NPN.jpg', list: ['Koleos_NPN-1.jpg'] },
          QXD: { base: 'Koleos_QXD.jpg', list: ['Koleos_QXD-1.jpg'] },
        },
        imgList: ['E2_&_E3_disks.jpg', 'E3_interior.jpg'],
        byOptions: [
          {
            base: 'Koleos_E3_seats_base(CUIR01).jpg',
            list: [{ img: 'Koleos_E3_seats_with_CUIR08.jpg', code: 'CUIR08' }],
          },
        ],
      },
    ],
  },
  R3U: {
    img: 'master.jpg',
    ext: [
      {
        filters: { eqp: ['Передній привод', 'Задній привод'] },
        colors: {
          '676': { base: 'Master_676.jpg', list: ['Master_676-1.jpg'] },
          '719': { base: 'Master_719.jpg', list: ['Master_719-1.jpg'] },
          J47: { base: 'Master_J47.jpg', list: ['Master_J47-1.jpg'] },
          KNH: { base: 'Master_KNH.jpg', list: ['Master_KNH-1.jpg'] },
          KPW: { base: 'Master_KPW.jpg', list: ['Master_KPW-1.jpg'] },
          QNG: { base: 'Master_QNG.jpg', list: ['Master_QNG-1.jpg'] },
          RNQ: { base: 'Master_RNQ.jpg', list: ['Master_RNQ-1.jpg'] },
        },
        byOptions: [
          {
            base: 'R3U_R3O_base_interior.jpg',
            list: [{ code: 'RA42D', img: 'R3U_R3O_with_RA42D.jpg' }],
          },
        ],
      },
    ],
  },
  R3O: {
    img: 'master_chassis.jpg',
    ext: [
      {
        filters: {
          eqp: ['Передній привод', 'Задній привод'],
          versions: [
            'TCC 1C2 3 D6',
            'TCC 1C3 3 D6',
            'PCC 1L3 4 F6',
            'PCC 1L3 5 F6',
            'PCC 1L4 5 F6',
            'PCC 1L4 5 P6',
          ], // Одинарна кабіна
        },
        colors: {
          QNG: { base: 'QNG_single.jpg', list: ['QNG_single-1.jpg'] },
        },
      },
      {
        filters: {
          eqp: ['Передній привод', 'Задній привод'],
          versions: [
            'TDC 1C3 3 D6',
            'PDC 1L3 5 F6',
            'PDC 1L4 5 F6',
            'PDC 1L4 5 P6',
          ], // Подвійна кабіна
        },
        colors: {
          QNG: { base: 'QNG_double.jpg', list: ['QNG_double-1.jpg'] },
        },
      },
      {
        filters: {
          eqp: ['Передній привод', 'Задній привод'],
        },
        byOptions: [
          {
            base: 'R3U_R3O_base_interior.jpg',
            list: [{ code: 'RA42D', img: 'R3U_R3O_with_RA42D.jpg' }],
          },
        ],
      },
    ],
  },
  DF1: {
    img: 'express_van.jpg',
    ext: [
      {
        colors: {
          '369': { base: 'DF1_369.jpg', list: [] },
          '676': { base: 'DF1_676.jpg', list: [] },
          KPW: { base: 'DF1_KPW.jpg', list: [] },
          KQA: { base: 'DF1_KQA.jpg', list: [] },
          RQH: { base: 'DF1_RQH.jpg', list: [] },
        },
      },
      {
        filters: {
          colors: ['369'],
        },
        byOptions: [
          {
            base: 'DF1_369-1.jpg',
            list: [{ code: 'PROJAB', img: 'DF1_369_with_PROJAB-1.jpg' }],
          },
        ],
      },
      {
        filters: {
          colors: ['676'],
        },
        byOptions: [
          {
            base: 'DF1_676-1.jpg',
            list: [{ code: 'PROJAB', img: 'DF1_676_with_PROJAB-1.jpg' }],
          },
        ],
      },
      {
        filters: {
          colors: ['KPW'],
        },
        byOptions: [
          {
            base: 'DF1_KPW-1.jpg',
            list: [{ code: 'PROJAB', img: 'DF1_KPW_with_PROJAB-1.jpg' }],
          },
        ],
      },
      {
        filters: {
          colors: ['KQA'],
        },
        byOptions: [
          {
            base: 'DF1_KQA-1.jpg',
            list: [{ code: 'PROJAB', img: 'DF1_KQA_with_PROJAB-1.jpg' }],
          },
        ],
      },
      {
        filters: {
          colors: ['RQH'],
        },
        byOptions: [
          {
            base: 'DF1_RQH-1.jpg',
            list: [{ code: 'PROJAB', img: 'DF1_RQH_with_PROJAB-1.jpg' }],
          },
        ],
      },
      {
        imgList: ['DF1_steering_wheel.jpg'],
        byOptions: [
          {
            base: 'DF1_disks_base_RDIF13.jpg',
            list: [{ code: 'RDIF10', img: 'DF1_disks_RDIF10.jpg' }],
          },
          {
            base: 'DK1_interior_base.jpg',
            list: [
              { code: 'PCU0W', img: 'DK1_interior_with_PCU0W.jpg' },
              { code: 'RA41A', img: 'DK1_interior_with_RA41A.jpg' },
            ],
          },
        ],
      },
    ],
  },
  DK1: {
    img: 'express.jpg',
    ext: [
      {
        filters: {
          eqp: ['Zen', 'Zen+'],
        },
        colors: {
          '369': { base: 'DK1_E2_369.jpg', list: ['DK1_E2_369-1.jpg'] },
          '676': { base: 'DK1_E2_676.jpg', list: ['DK1_E2_676-1.jpg'] },
          KPW: { base: 'DK1_E2_KPW.jpg', list: ['DK1_E2_KPW-1.jpg'] },
          KQA: { base: 'DK1_E2_KQA.jpg', list: ['DK1_E2_KQA-1.jpg'] },
          RQH: { base: 'DK1_E2_RQH.jpg', list: ['DK1_E2_RQH-1.jpg'] },
        },
        byOptions: [
          {
            base: 'DK1_disks_RDIF13.jpg',
            list: [{ code: 'RDIF30', img: 'DK1_disks_RDIF30.jpg' }],
          },
        ],
      },
      {
        filters: {
          eqp: ['Intense'],
        },
        colors: {
          '369': { base: 'DK1_E3_369.jpg', list: ['DK1_E3_369-1.jpg'] },
          '676': { base: 'DK1_E3_676.jpg', list: ['DK1_E3_676-1.jpg'] },
          KPW: { base: 'DK1_E3_KPW.jpg', list: ['DK1_E3_KPW-1.jpg'] },
          KQA: { base: 'DK1_E3_KQA.jpg', list: ['DK1_E3_KQA-1.jpg'] },
          RQH: { base: 'DK1_E3_RQH.jpg', list: ['DK1_E3_RQH-1.jpg'] },
        },
        byOptions: [
          {
            base: 'DK1_disks_RDIF30.jpg',
            list: [{ code: 'RDIF13', img: 'DK1_disks_RDIF13.jpg' }],
          },
        ],
      },
      {
        filters: {
          colors: ['RQH'],
        },
        imgList: [
          'DK1_RQH_back_3_4.jpg',
          'DK1_RQH_back_view.jpg',
          'DK1_RQH_open_door.jpg',
          'DK1_RQH_side_view.jpg',
        ],
      },
      {
        imgList: ['DK1_steering_wheel.jpg'],
        byOptions: [
          {
            base: 'DK1_interior_base.jpg',
            list: [
              { code: 'PCU0W', img: 'DK1_interior_with_PCU0W.jpg' },
              { code: 'RA41A', img: 'DK1_interior_with_RA41A.jpg' },
            ],
          },
        ],
      },
    ],
  },
  '46B': { img: '46B.jpg', ext: [] },
  '46K': { img: '46K.jpg', ext: [] },
  '46L': { img: '46L.jpg', ext: [] },
};

export const R_LINKS: { details: Record<string, string> } = {
  details: {
    JD1: 'https://www.renault.ua/passenger/duster.html',
    CP1: 'https://www.renault.ua/passenger/captur.html',
    ZGH: 'https://www.renault.ua/passenger/koleos.html',
    DK1: 'https://www.renault.ua/passenger/express.html',
    DF1: 'https://www.renault.ua/vans/express-van.html',
    R3U: 'https://www.renault.ua/vans/master.html',
    R3O: 'https://www.renault.ua/vans/master.html',
  },
};
