import useSWRImmutable from 'swr/immutable';

import {
  CloseButton,
  Col,
  Container,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap';
import { getCarList } from '../api';
import { Loader } from '../components/loader';
import { IModel } from '../types';
import { useEffect, useLayoutEffect, useState } from 'react';
import { FaFilter } from 'react-icons/fa';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Button } from '../components/button';
import { CarFullCard } from '../components/car-card';
import { Filters } from '../components/filters';
import { Info } from '../components/info';
import { RenaultSelect } from '../components/r-select';
import { useFilter } from '../hooks/useFilter';
import styles from './model.module.scss';
import { getPrice, isPC } from '../helpers';
import { Price } from '../components/price';
import { useMediaQuery } from '@mantine/hooks';
import { useFilteredData } from '../hooks/useFilteredData';
import { Badge, ScrollArea } from '@mantine/core';
import { checkDealerSite } from '../helpers/check-dealer-site';
import { Header } from '../components/header';
import { R_LINKS } from '../constants';
import { IoFilter } from 'react-icons/io5';
import { Footer } from '../components/footer';
import { FiFilter } from 'react-icons/fi';
import { Meta } from '../components/meta';

interface ISelect {
  value: string;
  label: string;
}

const sortOptions: ISelect[] = [
  { label: 'Рекомендована ціна менша', value: 'priceASC' },
  { label: 'Рекомендована ціна більша', value: 'priceDESC' },
  { label: 'Потужність менша', value: 'powerASC' },
  { label: 'Потужність більша', value: 'powerDESC' },
];

function Model() {
  const [modelCars, setModelCars] = useState<IModel[]>([]);
  const [modelList, setModelList] = useState<IModel[]>([]);
  const [filteredCars, setFilteredCars] = useState<IModel[]>();
  // const [toShowCars, setToShowCars] = useState<IModel[]>([]);
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const { filter } = useFilter(modelCars);
  const [loader, setLoader] = useState(true);
  const { isDealerSite } = checkDealerSite();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const [showFixedMenu, setShowFixedMenu] = useState(false);

  const {
    data: carList,
    error,
    isLoading,
  } = useSWRImmutable({ url: '/cars/list' }, getCarList);

  const sortCars = (sort: string) => (a: IModel, b: IModel) => {
    if (sort === 'priceASC') return getPrice(a) - getPrice(b);
    if (sort === 'priceDESC') return getPrice(b) - getPrice(a);
    if (sort === 'powerASC')
      return Number(a.version.powerHP) - Number(b.version.powerHP);
    if (sort === 'powerDESC')
      return Number(b.version.powerHP) - Number(a.version.powerHP);

    return Number(a.version.powerHP) - Number(b.version.powerHP);
  };

  useEffect(() => {
    if (!carList || !params.modelCode) return;

    setModelCars(carList.filter((c) => c.model.code === params.modelCode));
    setModelList(
      Array.from(new Map(carList.map((c) => [c.modelName, c])).values())
    );
  }, [carList, params.modelCode]);

  useLayoutEffect(() => {
    if (modelCars.length) setLoader(true);
  }, [modelCars, searchParams]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  }, [searchParams]);

  useEffect(() => {
    if (!modelCars?.length) return;

    const sortBy = searchParams.get('sort') || sortOptions[0].value;

    setFilteredCars(filter().sort(sortCars(sortBy)));
    setLoader(false);
  }, [modelCars, searchParams]);

  useEffect(() => {
    if (!modelCars.length) return;

    pushGAToParentPage({
      event: 'virtualPageview',
      businessType: 'new-car',
      pageType: 'stock-category',
      vehicleModel: modelCars[0].modelName,
      vehicleId: modelCars[0].model.code,
    });
  }, [modelCars]);

  const moreThanXs = useMediaQuery('(min-width: 576px)');
  const moreThanSm = useMediaQuery('(min-width: 768px)');
  // const filteredData = useFilteredData(modelCars);

  // const switchSearchFilter = (name: string, value?: any) => {
  //   let values = searchParams.getAll(name);
  //   searchParams.delete(name);

  //   if (values.includes(value)) {
  //     values = values.filter((t) => t !== value);
  //   } else {
  //     values.push(value);
  //   }

  //   values.forEach((v) => searchParams.append(name, v));
  //   setSearchParams(searchParams, { replace: true });
  // };

  const scrollHandler = () => {
    const scrolled = document.documentElement.scrollTop;
    const carListNode = document.documentElement.querySelector('#carList');
    const carListTop = carListNode?.getBoundingClientRect().top || 0;
    const carListBottom = carListNode?.getBoundingClientRect().bottom || 0;

    if (carListTop < 10 && carListBottom > 250) {
      setShowFixedMenu(true);
    } else {
      setShowFixedMenu(false);
    }
  };

  useEffect(() => {
    if (isDealerSite) return;

    window.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  });

  if (error) return <>Something went wrong</>;

  if (isLoading) return <Loader />;

  const promo0 = modelCars[0]?.model.promo?.find((p) => p.type === '0');

  return (
    <>
      {!!modelCars.length && (
        <Meta
          title={`Онлайн склад Renault ${modelCars[0].modelName} | Офіційний імпортер Renault в Україні`}
          description={`${modelCars[0].modelName} у наявності на складі офіційного імпортера Renault та в салонах дилерів в регіонах України`}
        />
      )}
      {!isDealerSite && (
        <Header
          action={
            modelCars.length
              ? {
                  text: `Детальніше ${modelCars[0].modelName}`,
                  url: R_LINKS.details[modelCars[0].model.code],
                }
              : undefined
          }
        />
      )}
      {!moreThanSm && (
        <div
          className={`${styles.fixedMenu} ${showFixedMenu ? 'visible' : ''}`}
        >
          <Row className="g-2">
            <Col xs={6}>
              <Button
                variant="super-primary"
                leftIcon={FiFilter}
                style={{
                  border: '1px solid #000',
                  height: 38,
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => setIsOpenFilters(!isOpenFilters)}
                children="фільтри"
              />
            </Col>
            <Col xs={6}>
              <RenaultSelect
                data={sortOptions}
                value={
                  sortOptions.find(
                    (so) => so.value === searchParams.get('sort')
                  )?.value
                }
                onChange={(value) => {
                  if (value) {
                    searchParams.set('sort', value);
                    setSearchParams(searchParams);
                  }
                }}
                icon={<IoFilter />}
                placeholder="сортування"
              />
            </Col>
          </Row>
        </div>
      )}

      <Row className="mt-2">
        <Col xs={12} md={4} lg={3} xxl={2}>
          <Row className="sticky-top" style={{ top: 12 }}>
            {moreThanSm && (
              <>
                {window.self !== window.top ? (
                  <Filters cars={modelCars} modelList={modelList} />
                ) : (
                  <ScrollArea
                    h="calc(100dvh - 30px)"
                    scrollbarSize={6}
                    className="pe-2"
                  >
                    <Filters cars={modelCars} modelList={modelList} />
                  </ScrollArea>
                )}
              </>
            )}
            {!moreThanSm && isOpenFilters && (
              <Modal
                show={isOpenFilters}
                onHide={() => setIsOpenFilters(false)}
                dialogClassName="d-md-none"
                fullscreen
                scrollable
              >
                <Modal.Body>
                  <div className="d-flex justify-content-end sticky-top">
                    <CloseButton
                      style={{ backgroundColor: '#fff', opacity: 0.8 }}
                      className="p-3 rounded-circle"
                      onClick={() => setIsOpenFilters(false)}
                    />
                  </div>
                  <Filters
                    cars={modelCars}
                    modelList={modelList}
                    close={() => setIsOpenFilters(false)}
                  />
                </Modal.Body>
              </Modal>
            )}
          </Row>
        </Col>
        <Col>
          <Row
            className={moreThanSm ? 'sticky-top' : ''}
            style={{
              background: '#fff',
              boxShadow: 'rgba(0, 0, 0, 0.2) 0px 11px 36px -16px',
            }}
          >
            <Col xs={12} md={6}>
              <Link to={`/`}>
                <Button variant="primary-link" className="ps-0">
                  Всі моделі
                </Button>
              </Link>
              <Button variant="primary-link" active className="ps-0">
                {modelCars[0]?.modelName}
              </Button>
              <h1 className={styles.amountTitle}>
                <span
                  style={{
                    minWidth: 41,
                    minHeight: 19,
                    display: 'inline-flex',
                    justifyContent: 'center',
                  }}
                >
                  {loader ? (
                    <Spinner animation="grow" variant="warning" size="sm" />
                  ) : (
                    filteredCars?.length
                  )}
                </span>
                {' НОВИХ АВТО'}
              </h1>
            </Col>
            {!moreThanSm && (
              <Col xs={12} className="mb-2">
                <Filters cars={modelCars} modelList={modelList} type="short" />
              </Col>
            )}
            <Col
              xs={6}
              sm={6}
              md={0}
              className="d-block d-md-none mb-2 pe-1 pe-sm-3"
            >
              {!isOpenFilters && (
                <Button
                  variant="super-primary"
                  className="w-100 px-1"
                  style={{
                    border: '1px solid #000',
                    height: 39,
                    fontSize: 15,
                  }}
                  onClick={() => setIsOpenFilters(!isOpenFilters)}
                >
                  <span>більше фільтрів</span>
                </Button>
              )}
            </Col>
            <Col xs={6} sm={6} md={6} className="ps-1 ps-sm-3">
              <div
                className="d-block d-md-flex justify-content-end mt-md-2"
                style={{ fontSize: 14 }}
              >
                <RenaultSelect
                  data={sortOptions}
                  value={
                    sortOptions.find(
                      (so) => so.value === searchParams.get('sort')
                    )?.value
                  }
                  onChange={(value) => {
                    if (value) {
                      searchParams.set('sort', value);
                      setSearchParams(searchParams);
                    }
                  }}
                  placeholder="сортування"
                  customStyles={{
                    wrapper: {
                      width: moreThanSm ? 'auto' : '100%',
                    },
                  }}
                />
              </div>
            </Col>
            {/* <Col xs={12}>
              <div
                className="d-flex flex-column gap-2 my-2"
                style={{ fontSize: 13.5, lineHeight: 1.1 }}
              >
                <div className="d-inline-flex flex-wrap align-items-center gap-2">
                  <div>Коробка</div>
                  {filteredData.gearboxTypeList?.map((i) => (
                    <Badge
                      key={i.id}
                      color="dark"
                      radius="xs"
                      px={4}
                      style={{ cursor: 'pointer' }}
                      variant={
                        searchParams.getAll('gearbox').includes(i.gearbox)
                          ? 'filled'
                          : 'outline'
                      }
                      onClick={() => {
                        switchSearchFilter('gearbox', i.gearbox);
                      }}
                    >
                      {i.gearbox} ({i.gearboxTypeAmount})
                    </Badge>
                  ))}
                </div>
                <div className="d-inline-flex flex-wrap align-items-center gap-2">
                  <div>Пальне</div>
                  {filteredData.fuelTypeList?.map((i) => (
                    <Badge
                      key={i.id}
                      color="dark"
                      radius="xs"
                      px={4}
                      style={{ cursor: 'pointer' }}
                      variant={
                        searchParams.getAll('fuelType').includes(i.fuelType)
                          ? 'filled'
                          : 'outline'
                      }
                      onClick={() => {
                        switchSearchFilter('fuelType', i.fuelType);
                      }}
                    >
                      {i.fuelType} ({i.fuelTypeAmount})
                    </Badge>
                  ))}
                </div>
              </div>
            </Col> */}
          </Row>

          <Row
            xs={2}
            sm={2}
            md={2}
            lg={3}
            xl={4}
            xxl={5}
            className="g-1 mt-2"
            id="carList"
          >
            {filteredCars?.map((model) => (
              <Col key={model.vin}>
                <CarFullCard car={model} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Info className="mt-3" id="info">
        <>
          <p>
            Наведена інформація в онлайн-складі про комплектації, технічні
            характеристики, рекомендовані клієнтські ціни тощо не є пропозицією
            укласти договір (офертою).
          </p>
          <p>
            Зовнішній вигляд автомобіля може відрізнятись від зображеного в
            онлайн-складі, що може бути версією автомобіля для інших ринків.
            Автомобіль на зображенні не обов'язково відповідає конкретним
            моделям, варіантам комплектації або пропозиціям. Деякі зображені
            елементи можуть бути недоступні або доступні тільки в певній
            комплектації чи у якості аксесуарів.
          </p>
          <p>
            *Вказані ціни є рекомендованими роздрібними (клієнтськими) цінами та
            не включають вартість додаткового обладнання, аксесуарів, що можуть
            бути встановлені за окремим замовленням, послуг, спеціальних акцій і
            пропозицій, які можуть діяти на момент придбання автомобіля у
            дилерів Renault в Україні. Роздрібна ціна автомобіля встановлюється
            дилерами Renault самостійно та може відрізнятись від зазначеної
            рекомендованої клієнтської ціни. Для отримання інформації по
            встановленій дилерами остаточній роздрібній ціні звертайтеся до
            офіційних дилерів Renault в Україні. АТ "Рено Україна" залишає за
            собою право змінювати рекомендовану роздрібну ціну шляхом
            публікування інформації про такі ціни на своєму веб-сайті.
          </p>
          {['R3U', 'R3O', 'DF1'].includes(params.modelCode || '') ? (
            <p>
              *Спеціальна ціна за умовами придбання в кредит за програмою
              «Renault Бонус» від Mobilize Financial Services для фізичних осіб.
              Під знижкою <Price currency="грн.">{promo0?.value || 0}</Price>{' '}
              мається на увазі сума зменшення рекомендованої клієнтської
              вартості автомобіля до спеціальної ціни за умови придбання у
              кредит за програмою «Renault Бонус». Ви можете отримати кредит за
              програмою «Renault Бонус» від Mobilize Financial Services для
              придбання автомобіля на наступних умовах: максимальний строк
              кредиту – 36 місяців, розмір першого внеску – від 30 % до 50 %
              (максимальний) від вартості автомобіля, максимальна сума кредиту –
              2 600 000 грн., процентна ставка – 14,9 % річних, комісія за
              видачу кредиту – 2,9 %, комісія за операції в Державному реєстрі
              обтяжень рухомого майна – 750 грн. Реальна річна процентна ставка
              становить 41,7 % річних (розрахована виходячи з максимальної суми
              кредиту та максимального строку користування кредитом). Кредитує
              АТ «КРЕДІ АГРІКОЛЬ БАНК». Ліцензія НБУ №99 від 12.10.2011.
              Реєстраційний № 149 у Державному реєстрі банків. Кредит надається
              у відділеннях Банку на всій території України крім тимчасово
              окупованих територій та територій, на яких державні органи України
              тимчасово не здійснюють свої повноваження (території, що
              перебувають в оточенні (блокуванні) та/або території на яких
              тривають активні бойові дії), внаслідок збройної агресії
              Російської федерації проти України розпочатої 24 лютого 2022 року.
              Не кредитуються області (якщо клієнт проживає та/або отримує дохід
              від бізнесу в цих областях): Сумську, Харківську, Луганську,
              Донецьку, Миколаївську, Херсонську, Запорізьку, АРК. З метою
              отримання інформації щодо оформлення кредиту звертайтеся за
              телефонами гарячої лінії 0800305555 або на сайті банку
              www.credit-agricole.ua. Банк залишає за собою право змінювати
              умови кредитування. Детальні умови кредитування на сайті
              www.credit-agricole.ua. Замовником реклами є АТ «КРЕДІ АГРІКОЛЬ
              БАНК». Для отримання кредиту згідно з програмою «Renault Бонус»
              від Mobilize Financial Services обов'язково придбання наступних
              послуг: страхування КАСКО та ОСЦПВ (надає СК «ARX»: Ліцензія
              Держфінпослуг серія АВ №483293 від 17.09.2009 р., Ліцензія
              Держфінпослуг серія АГ №569137 від 24.12.2010 р.; СК «УНІКА»:
              Ліцензія Держфінпослуг серія АЕ №293990 від 07.08.2014 р.,
              Ліцензія Держфінпослуг серія АЕ №293760 від 21.05.2014 р.; СК
              «Українська страхова група»: Ліцензія Держфінпослуг серія АГ №
              569295 від 21.01.2011 р., Ліцензія Держфінпослуг серія АВ №500320
              від 13.01.2010 р.; СК «ПЗУ УКРАЇНА»: Ліцензія Держфінпослуг серія
              АВ №500102 від 15.12.2009 р., Ліцензія Держфінпослуг серія АГ
              №569236 від 14.01.2011 р.) та страхування життя і здоров'я
              позичальника (надає СК «УНІКА»: Ліцензія Держфінпослуг серія АЕ
              №293990 від 07.08.2014 р., Ліцензія Держфінпослуг серія АЕ №293760
              від 21.05.2014 р.).
            </p>
          ) : (
            <p>
              *Спеціальна ціна за умовами придбання в кредит за програмою
              «Renault Бонус» від Mobilize Financial Services для фізичних осіб.
              Кредитні умови за програмою «Renault Бонус»: максимальний строк
              кредиту – 60 місяці, розмір першого внеску – від 20% до 50%
              (максимальний) від вартості автомобіля, максимальна сума кредиту –
              2 600 000 грн., процентна ставка – 12,9 % річних, комісія за
              видачу кредиту – 2,9 %, комісія за операції в Державному реєстрі
              обтяжень рухомого майна – 750 грн. Реальна річна процентна ставка
              становить 37,7 % річних (розрахована виходячи з максимальної суми
              кредиту та максимального строку користування кредитом). Кредит
              надається у відділеннях Банку на всій території України крім
              тимчасово окупованих територій та територій, на яких державні
              органи України тимчасово не здійснюють свої повноваження
              (території, що перебувають в оточенні (блокуванні) та/або
              території на яких тривають активні бойові дії), внаслідок збройної
              агресії Російської федерації проти України розпочатої 24 лютого
              2022 року. Не кредитуються області (якщо клієнт проживає та/або
              отримує дохід від бізнесу в цих областях): Луганську, Донецьку,
              Херсонську, Запорізьку, АРК. З метою отримання інформації щодо
              оформлення кредиту звертайтеся за телефонами гарячої лінії
              0800305555 або на сайті банку www.credit-agricole.ua. Кредитує АТ
              «КРЕДІ АГРІКОЛЬ БАНК». Ліцензія НБУ №99 від 12.10.2011.
              Реєстраційний № 149 у Державному реєстрі банків. Банк залишає за
              собою право змінювати умови кредитування. Детальні умови
              кредитування на сайті www.credit-agricole.ua. Замовником реклами
              кредитних послуг є АТ «КРЕДІ АГРІКОЛЬ БАНК». Для отримання кредиту
              згідно з програмою «Renault Бонус» обов'язково придбання наступних
              послуг: страхування КАСКО та ОСЦПВ (надає СК «ARX»: Ліцензія
              Держфінпослуг серія АВ №483293 від 17.09.2009 р., Ліцензія
              Держфінпослуг серія АГ №569137 від 24.12.2010 р.; СК «УНІКА»:
              Ліцензія Держфінпослуг серія АЕ №293990 від 07.08.2014 р.,
              Ліцензія Держфінпослуг серія АЕ №293760 від 21.05.2014 р.; СК
              «Українська страхова група»: Ліцензія Держфінпослуг серія АГ №
              569295 від 21.01.2011 р., Ліцензія Держфінпослуг серія АВ №500320
              від 13.01.2010 р.; СК «ПЗУ УКРАЇНА»: Ліцензія Держфінпослуг серія
              АВ №500102 від 15.12.2009 р., Ліцензія Держфінпослуг серія АГ
              №569236 від 14.01.2011 р.) та страхування життя і здоров'я
              позичальника (надає СК «УНІКА»: Ліцензія Держфінпослуг серія АЕ
              №293990 від 07.08.2014 р., Ліцензія Держфінпослуг серія АЕ №293760
              від 21.05.2014 р.)
            </p>
          )}

          <p>
            Терміни «На складі», «В дорозі», «Дилер» - означають
            місцезнаходження автомобіля станом на дату оновлення даних он-лайн
            складу, а саме: на складі імпортера в Україні, у процесі
            транспортування до складу імпортера, на складі у дилера, відповідно.
            Щодо автомобілів «На складі», «В дорозі» всі деталі замовлення та
            доставки можливо уточнювати в обраного споживачем дилера офіційної
            дилерської мереж Рено. Щодо деталей замовлення автомобілів зі
            статусом «Дилер» необхідно звертатись безпосередньо до вказаного
            дилера.
          </p>
          <p>
            Наведена інформація в онлайн складі може бути не остаточною і
            підлягає уточненню у дилера Renault в Україні.
          </p>
          <p>
            Гаряча лінія Renault:
            <a className="px-1" href="tel: 0 800 500 351">
              0-800-500-351
            </a>
            (усі дзвінки в межах України зі стаціонарних і мобільних номерів
            українських операторів зв’язку – безкоштовні). Перелік дилерів, що
            здійснюють свою діяльність, зазначено на сайті
            <a
              className="px-1"
              href="//www.renault.ua"
              target="_blank"
              rel="noreferrer"
            >
              www.renault.ua.
            </a>
            Режим роботи обраного дилерського центру необхідно уточнювати
            безпосередньо в такого дилера.
          </p>
        </>
      </Info>
      {!isDealerSite && <Footer />}
    </>
  );
}

export default Model;
