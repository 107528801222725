const staticValues = ['На складі імпортера', 'Київ'];

export const sortCities = (a: string, b: string) => {
  const aIsStatic = staticValues.includes(a);
  const bIsStatic = staticValues.includes(b);

  if (aIsStatic && bIsStatic) {
    return staticValues.indexOf(a) - staticValues.indexOf(b);
  }
  return aIsStatic ? -1 : bIsStatic ? 1 : a.localeCompare(b);
};
