import { Col, Row } from 'react-bootstrap';
import { ReactComponent as Logo } from '../static/images/logo.svg';
import styles from './header.module.scss';
import { Button } from './button';

interface IHeaderProps {
  action?: {
    text: string;
    url: string;
  };
}

export function Header({ action }: IHeaderProps) {
  return (
    <Row className={styles.wrapper}>
      <Col className="py-2" xs="auto">
        <a href="//renault.ua">
          <Logo height={35} fill="#fff" />
        </a>
      </Col>
      {!!action && (
        <Col className="d-flex align-items-center justify-content-end">
          <a href={action.url}>
            <Button variant="secondary-text">{action.text}</Button>
          </a>
        </Col>
      )}
    </Row>
  );
}
