import axios, { AxiosRequestConfig } from 'axios';
import { IDealer, IModel } from '../types';
import { checkDealerSite } from '../helpers/check-dealer-site';
import { FakeBir } from '../constants';

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const baseURL = isDev ? 'https://warehouse.stg.rnlt.pp.ua/api' : '/api';

export const api = axios.create({
  baseURL,
  method: 'get',
});

export const fetcher = async <T>(config: AxiosRequestConfig<any>) => {
  const response = await api.request<{ data: T }>(config);
  return response.data as T;
};

export const getCarList = async (): Promise<IModel[]> => {
  const { data } = await api.request<IApiResponse<IModel[]>>({
    url: '/cars/list',
  });

  const { isDealerSite, dealerBir } = checkDealerSite();

  if (!isDealerSite) return data.data;

  return data.data.filter(
    (car) =>
      car.dealer.bir === dealerBir || car.dealer.bir === FakeBir.importCenter
  );
};

export const getDealerList = async (): Promise<IDealer[]> => {
  const { data } = await api.request<IApiResponse<IDealer[]>>({
    url: '/dealers/list',
  });

  const { isDealerSite, dealerBir } = checkDealerSite();

  if (!isDealerSite) return data.data;

  return data.data.filter(({ bir }) => bir === dealerBir);
};

export interface IApiResponse<T> {
  status: string;
  data: T;
  timestamp: string;
}
