import { RangeSlider, ScrollArea, rem } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useEffect, useMemo, useState } from 'react';
import { Accordion, Col, Form, Row } from 'react-bootstrap';
import { TbDirectionHorizontal } from 'react-icons/tb';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Button } from '../components/button';
import { useFilter } from '../hooks/useFilter';
import { IModel } from '../types';
import { getPrice } from '../helpers';
import { useFilteredData } from '../hooks/useFilteredData';

interface IFiltersProps {
  cars: IModel[];
  modelList: IModel[];
  close?: () => void;
  type?: 'short' | 'full';
}

export function Filters({
  cars,
  modelList,
  close,
  type = 'full',
}: IFiltersProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();

  const { minPrice, maxPrice } = useMemo(() => {
    if (!cars.length) return { minPrice: '', maxPrice: '' };
    const min = Math.min(...cars.filter((c) => Number(c.price)).map(getPrice));
    const max = Math.max(...cars.map(getPrice));
    return {
      minPrice: Math.ceil(min).toString(),
      maxPrice: Math.ceil(max).toString(),
    };
  }, [cars, params.modelCode]);

  const [priceRange, setPriceRange] = useState({
    min: searchParams.get('priceMin') || minPrice || '',
    max: searchParams.get('priceMax') || maxPrice || '',
  });

  const [priceRangeDebounced] = useDebouncedValue(priceRange, 500);

  useEffect(() => {
    setPriceRange({
      ...priceRange,
      ...(minPrice !== null &&
        !searchParams.get('priceMin') && { min: minPrice }),
      ...(maxPrice !== null &&
        !searchParams.get('priceMax') && { max: maxPrice }),
    });
  }, [minPrice, maxPrice]);

  // useEffect(() => {
  //   const priceMin = searchParams.get('priceMin');
  //   const priceMax = searchParams.get('priceMax');
  //   setPriceRange({
  //     ...priceRange,
  //     ...(priceMin !== null && { min: priceMin }),
  //     ...(priceMax !== null && { max: priceMax }),
  //   });
  // }, [searchParams]);

  useEffect(() => {
    if (!Number(priceRange.min) || !Number(priceRange.max)) return;

    const min = priceRange.min.replace(/\s/g, '');
    const max = priceRange.max.replace(/\s/g, '');

    if (searchParams.get('priceMin') || min !== minPrice) {
      searchParams.set('priceMin', min);
    }
    if (searchParams.get('priceMax') || max !== maxPrice) {
      searchParams.set('priceMax', max);
    }
    setSearchParams(searchParams, { replace: true });
  }, [priceRangeDebounced]);

  const {
    cityList,
    dealerList,
    colorList,
    fuelTypeList,
    gearboxTypeList,
    powerHPList,
    driveList,
    yearList,
    eqpLevelList,
  } = useFilteredData(cars);

  const resetFilters = () => {
    setSearchParams({}, { replace: true });
    setPriceRange({
      min: minPrice,
      max: maxPrice,
    });
  };

  const changeFilterHandle = (
    e: React.ChangeEvent<HTMLInputElement>,
    paramName: string,
    checkedValue: string
  ) => {
    const { checked } = e.target;
    if (checked) {
      searchParams.append(paramName, checkedValue);
    } else {
      const params = searchParams.getAll(paramName);
      searchParams.delete(paramName);
      params
        .filter((p) => p !== checkedValue)
        .forEach((p) => searchParams.append(paramName, p));
    }
    setSearchParams(searchParams, { replace: true });
  };

  const isDiscount = !!searchParams.get('isDiscount');

  return (
    <>
      {' '}
      <Accordion flush style={{ fontSize: 14 }}>
        <Accordion.Item eventKey="-1" id="filter-city">
          <Accordion.Header>Місце знаходження</Accordion.Header>
          <Accordion.Body>
            {cityList.map(({ carAmount, city }, i) => (
              <div key={city} className="d-flex">
                <Form.Check
                  id={`city-${city}`}
                  type="checkbox"
                  label={city}
                  className="flex-grow-1"
                  checked={
                    !!searchParams.getAll('city').find((d) => d === city)
                  }
                  onChange={(e) => changeFilterHandle(e, 'city', city)}
                />
                <div className="ps-2">{carAmount}</div>
              </div>
            ))}
          </Accordion.Body>
        </Accordion.Item>
        {type === 'full' && (
          <>
            <Accordion.Item eventKey="0" id="filter-dealer">
              <Accordion.Header>Дилер</Accordion.Header>
              <Accordion.Body>
                {dealerList.map(({ bir, name, carAmount, city }, i) => (
                  <div key={bir} className="d-flex">
                    <Form.Check
                      id={`dealer-${bir}`}
                      type="checkbox"
                      label={name}
                      className="flex-grow-1"
                      checked={
                        !!searchParams.getAll('dealer').find((d) => d === bir)
                      }
                      onChange={(e) => changeFilterHandle(e, 'dealer', bir)}
                    />
                    <div className="ps-2">{carAmount}</div>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" id="filter-price">
              <Accordion.Header>Ціна</Accordion.Header>
              <Accordion.Body>
                <Row className="g-2">
                  <Col>
                    <Form.Label children="від" />
                    <Form.Control
                      style={{ fontSize: 15 }}
                      value={
                        priceRange.min
                          ?.toString()
                          .replace(/(?=(?:.{3})*$)/g, ' ')
                          .trim() || ''
                      }
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value) {
                          if (!value.match(/^[\d\s]+$/))
                            return e.preventDefault();
                        }
                        // searchParams.set('priceMin', value.replace(/\s/g, ''));
                        // setSearchParams(searchParams);
                        setPriceRange({
                          ...priceRange,
                          min: value.replace(/\s/g, ''),
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <Form.Label children="до" />
                    <Form.Control
                      style={{ fontSize: 15 }}
                      value={
                        priceRange.max
                          ?.toString()
                          .replace(/(?=(?:.{3})*$)/g, ' ')
                          .trim() || ''
                      }
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value) {
                          if (!value.match(/^[\d\s]+$/))
                            return e.preventDefault();
                        }
                        // searchParams.set('priceMax', value.replace(/\s/g, ''));
                        // setSearchParams(searchParams);
                        setPriceRange({
                          ...priceRange,
                          max: value.replace(/\s/g, ''),
                        });
                      }}
                    />
                  </Col>
                </Row>
                <RangeSlider
                  mt="md"
                  styles={{ thumb: { borderWidth: rem(2), padding: rem(3) } }}
                  color="dark"
                  label={null}
                  // step={1000}
                  min={+minPrice}
                  max={+maxPrice}
                  value={[+priceRange.min, +priceRange.max]}
                  thumbSize={24}
                  thumbChildren={[
                    <TbDirectionHorizontal key="1" />,
                    <TbDirectionHorizontal key="2" />,
                  ]}
                  onChange={([min, max]) =>
                    setPriceRange({ min: min.toString(), max: max.toString() })
                  }
                  onChangeEnd={([min, max]) => {
                    searchParams.set('priceMin', min.toString());
                    searchParams.set('priceMax', max.toString());
                    setSearchParams(searchParams, { replace: true });
                  }}
                />

                <div className="d-flex mt-3">
                  <Form.Check
                    id={`isDiscount`}
                    type="checkbox"
                    label={`Автомобілі з акцією`}
                    className="flex-grow-1"
                    checked={isDiscount}
                    onChange={(e) => changeFilterHandle(e, 'isDiscount', '1')}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="model" id="filter-model">
              <Accordion.Header>Модель</Accordion.Header>
              <Accordion.Body>
                {modelList.map(({ id, model, modelName }, i) => (
                  <div key={id}>
                    <Link to={`/${model.code}`} replace>
                      <Button variant="primary-link">
                        <span
                          style={{
                            textDecoration:
                              params.modelCode === model.code
                                ? 'underline'
                                : '',
                          }}
                        >
                          {modelName}
                        </span>
                      </Button>
                    </Link>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="equipment" id="filter-eqp">
              <Accordion.Header>Комплектація</Accordion.Header>
              <Accordion.Body>
                {eqpLevelList.map(({ eqpLevel, eqpLevelAmount }, i) => {
                  return (
                    <div key={eqpLevel} className="d-flex align-items-center">
                      <Form.Check
                        id={`eqpLevel-${eqpLevel}`}
                        type="checkbox"
                        label={eqpLevel}
                        className={`flex-grow-1`}
                        checked={
                          !!searchParams
                            .getAll('eqpLevel')
                            .find((c) => c === eqpLevel)
                        }
                        onChange={(e) =>
                          changeFilterHandle(e, 'eqpLevel', eqpLevel)
                        }
                      />
                      <div className="ps-2">{eqpLevelAmount}</div>
                    </div>
                  );
                })}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" id="filter-color">
              <Accordion.Header>Колір</Accordion.Header>
              <Accordion.Body>
                {colorList.map(
                  ({ colorCode, colorAmount, colorName, colorsHex }, i) => {
                    const singleColor = colorsHex.length === 1;
                    const colors = colorsHex.map((c, i, arr) => {
                      const part = Math.round(100 / arr.length);
                      const from = i * part;
                      return `${c} ${from}% ${from + part}%`;
                    });
                    const isWhite = colorsHex.find(
                      (c) => c?.toUpperCase() === '#FFFFFF'
                    );
                    return (
                      <div
                        key={colorCode}
                        className="d-flex align-items-center"
                      >
                        <Form.Check
                          id={`color-${colorCode}`}
                          type="checkbox"
                          label={
                            <div className="d-flex align-content-center">
                              <span
                                className=" d-inline-flex me-2 flex-shrink-0"
                                style={{
                                  width: 19,
                                  height: 19,
                                  background: singleColor
                                    ? colorsHex[0] || 'yellow'
                                    : `linear-gradient(to right, ${colors})`,
                                  borderRadius: '50%',
                                  border: isWhite
                                    ? '1px dashed #000'
                                    : undefined,
                                }}
                              ></span>
                              <span>{colorName || colorCode}</span>
                            </div>
                          }
                          className={`flex-grow-1`}
                          checked={
                            !!searchParams
                              .getAll('color')
                              .find((c) => c === colorCode)
                          }
                          onChange={(e) =>
                            changeFilterHandle(e, 'color', colorCode)
                          }
                        />
                        <div className="ps-2">{colorAmount}</div>
                      </div>
                    );
                  }
                )}
              </Accordion.Body>
            </Accordion.Item>
          </>
        )}
        <Accordion.Item eventKey="3" id="filter-gearbox">
          <Accordion.Header>Коробка передач</Accordion.Header>
          <Accordion.Body>
            {gearboxTypeList.map(({ gearbox, gearboxTypeAmount }, i) => (
              <div key={gearbox} className="d-flex">
                <Form.Check
                  id={`gearbox-${gearbox}`}
                  type="checkbox"
                  label={gearbox}
                  className="flex-grow-1"
                  checked={
                    !!searchParams.getAll('gearbox').find((c) => c === gearbox)
                  }
                  onChange={(e) => changeFilterHandle(e, 'gearbox', gearbox)}
                />
                <div className="ps-2">{gearboxTypeAmount}</div>
              </div>
            ))}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4" id="filter-fuelType">
          <Accordion.Header>Тип пального</Accordion.Header>
          <Accordion.Body>
            {fuelTypeList.map(({ fuelType, fuelTypeAmount }, i) => (
              <div key={fuelType} className="d-flex">
                <Form.Check
                  id={`fuelType-${fuelType}`}
                  type="checkbox"
                  label={fuelType}
                  className="flex-grow-1"
                  checked={
                    !!searchParams
                      .getAll('fuelType')
                      .find((c) => c === fuelType)
                  }
                  onChange={(e) => changeFilterHandle(e, 'fuelType', fuelType)}
                />
                <div className="ps-2">{fuelTypeAmount}</div>
              </div>
            ))}
          </Accordion.Body>
        </Accordion.Item>
        {type === 'full' && (
          <>
            <Accordion.Item eventKey="5" id="filter-powerHP">
              <Accordion.Header>Двигун</Accordion.Header>
              <Accordion.Body>
                {powerHPList.map(
                  (
                    { powerHP, powerHPAmount, version: { engineCapacity } },
                    i
                  ) => {
                    const engine = formatEngine(engineCapacity, powerHP);

                    return (
                      <div key={powerHP} className="d-flex">
                        <Form.Check
                          id={`powerHP-${powerHP}`}
                          type="checkbox"
                          label={engine}
                          className="flex-grow-1"
                          checked={
                            !!searchParams
                              .getAll('powerHP')
                              .find((c) => c === powerHP)
                          }
                          onChange={(e) =>
                            changeFilterHandle(e, 'powerHP', powerHP)
                          }
                        />
                        <div className="ps-2">{powerHPAmount}</div>
                      </div>
                    );
                  }
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6" id="filter-drive">
              <Accordion.Header>Привод</Accordion.Header>
              <Accordion.Body>
                {driveList.map(({ drive, driveAmount }, i) => (
                  <div key={drive} className="d-flex">
                    <Form.Check
                      id={`drive-${drive}`}
                      type="checkbox"
                      label={drive}
                      className="flex-grow-1"
                      checked={
                        !!searchParams.getAll('drive').find((c) => c === drive)
                      }
                      onChange={(e) => changeFilterHandle(e, 'drive', drive)}
                    />
                    <div className="ps-2">{driveAmount}</div>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7" id="filter-year">
              <Accordion.Header>Piк виробництва</Accordion.Header>
              <Accordion.Body>
                {yearList.map(({ year, yearAmount }, i) => (
                  <div key={year} className="d-flex">
                    <Form.Check
                      id={`year-${year}`}
                      type="checkbox"
                      label={year}
                      className="flex-grow-1"
                      checked={
                        !!searchParams.getAll('year').find((c) => c === year)
                      }
                      onChange={(e) => changeFilterHandle(e, 'year', year)}
                    />
                    <div className="ps-2">{yearAmount}</div>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </>
        )}
      </Accordion>
      {type === 'full' && (
        <>
          <div className="text-center mt-3 mb-3">
            <Button
              variant="secondary"
              onClick={() => {
                resetFilters();
                close && close();
              }}
            >
              Очистити фільтри
            </Button>
            <Button
              variant="primary"
              className="d-md-none mt-2 ms-2"
              onClick={close}
            >
              Фільтрувати
            </Button>
          </div>
        </>
      )}
    </>
  );
}

export const formatEngine = (engineCapacity: string, powerHP: string) => {
  const capacity = (Number(engineCapacity) / 1000).toFixed(1);
  const engine = Number(engineCapacity)
    ? `${capacity} (${powerHP} к.с)`
    : `${powerHP} к.с`;
  return engine;
};
