import { useSearchParams } from 'react-router-dom';
import { SedreStatus } from '../constants';
import { IModel } from '../types';
import { getPrice } from '../helpers';
import { getCityName } from '../helpers/get-city-name';

export function useFilter(cars: IModel[]) {
  const [searchParams, setSearchParams] = useSearchParams();

  type FilterType =
    | 'city'
    | 'dealer'
    | 'color'
    | 'fuelType'
    | 'gearbox'
    | 'powerHP'
    | 'year'
    | 'drive'
    | 'eqpLevel'
    | 'isDiscount';
  interface IFilterProps {
    skip?: FilterType[];
  }

  const filter = (props?: IFilterProps) => {
    let res = [...cars];
    const { skip = [] } = props || {};

    const isDiscount = searchParams.get('isDiscount');
    if (isDiscount && !skip?.includes('isDiscount')) {
      res = res.filter((c) => !!c.discount);
    }

    const selEqpLevels = searchParams.getAll('eqpLevel').filter(Boolean);
    if (selEqpLevels.length && !skip?.includes('eqpLevel')) {
      res = res.filter((c) => selEqpLevels.includes(c.version.equipmentName));
    }

    const selPriceMin = searchParams.get('priceMin');
    if (selPriceMin) {
      res = res.filter((c) => Math.ceil(getPrice(c)) >= Number(selPriceMin));
    }
    const selPriceMax = searchParams.get('priceMax');
    if (selPriceMax) {
      res = res.filter((c) => Math.ceil(getPrice(c)) <= Number(selPriceMax));
    }

    const selCities = searchParams.getAll('city').filter(Boolean);
    if (selCities.length && !skip?.includes('city')) {
      res = res.filter((c) => selCities.includes(getCityName(c)));
    }

    const selDealers = searchParams.getAll('dealer').filter(Boolean);
    if (selDealers.length && !skip?.includes('dealer')) {
      res = res.filter((c) => selDealers.includes(c.dealer.bir));
    }

    const selColors = searchParams.getAll('color').filter(Boolean);
    if (selColors.length && !skip?.includes('color')) {
      res = res.filter((c) => selColors.includes(c.color));
    }

    const selFuelTypes = searchParams.getAll('fuelType').filter(Boolean);
    if (selFuelTypes.length && !skip?.includes('fuelType')) {
      res = res.filter((c) => selFuelTypes.includes(c.version.fuelType));
    }

    const selGearboxList = searchParams.getAll('gearbox').filter(Boolean);
    if (selGearboxList.length && !skip?.includes('gearbox')) {
      res = res.filter((c) => selGearboxList.includes(c.version.gearbox));
    }

    const selPowerHPs = searchParams.getAll('powerHP').filter(Boolean);
    if (selPowerHPs.length && !skip?.includes('powerHP')) {
      res = res.filter((c) => selPowerHPs.includes(c.version.powerHP));
    }

    const selYears = searchParams.getAll('year').filter(Boolean);
    if (selYears.length && !skip?.includes('year')) {
      res = res.filter((c) =>
        selYears.includes(new Date(c.releaseYear).getFullYear().toString())
      );
    }

    if (!selDealers.length && !skip?.includes('dealer')) {
      res = res.filter((c) =>
        SedreStatus.inUkraine.includes(c.sedreStatus.status)
      );
    }

    const selDrive = searchParams.getAll('drive').filter(Boolean);
    if (selDrive.length && !skip?.includes('drive')) {
      res = res.filter((c) => selDrive.includes(c.version.drive));
    }

    return res;
  };

  return { filter };
}
