import { PropsWithChildren } from 'react';

import styles from './label.module.scss';

interface ILabelProps {
  className?: string;
}

export function Label({
  children,
  className = '',
}: PropsWithChildren<ILabelProps>) {
  return <span className={styles.label + ` ${className}`}>{children}</span>;
}
