import { forwardRef, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FaMapMarkerAlt, FaWarehouse } from 'react-icons/fa';
import { IoCarSportSharp, IoConstruct } from 'react-icons/io5';
import { useSearchParams } from 'react-router-dom';
import useSWRImmutable from 'swr/immutable';
import { getCarList } from '../api';
import { Banner } from '../components/banner';
import { CarShortCard } from '../components/car-card';
import { Info } from '../components/info';
import { Label } from '../components/label';
import { Loader } from '../components/loader';
import { RenaultSelect } from '../components/r-select';
import { FakeBir, SedreStatus } from '../constants';
import { IDealer, IModel } from '../types';
import styles from './models.module.scss';
import { getPrice, isLCV, isPC } from '../helpers';
import { sortCities } from '../helpers/sort-cities';
import { getCityName } from '../helpers/get-city-name';
import { checkDealerSite } from '../helpers/check-dealer-site';
import { Header } from '../components/header';
import { Footer } from '../components/footer';
import { Meta } from '../components/meta';

interface IAvModelList {
  cars: IModel[];
  colors: number;
  code: string;
  name: string;
  minPrice: number;
  segment: IModel['model']['segment'];
}

function Models() {
  const [dealerList, setDealerList] = useState<IModel['dealer'][]>([]);
  const [filteredModels, setFilteredModels] = useState<IAvModelList[]>([]);
  const [models, setModels] = useState<IAvModelList[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isDealerSite } = checkDealerSite();

  const {
    data: carList,
    error,
    isLoading,
  } = useSWRImmutable({ url: '/cars/list' }, getCarList);

  const city = searchParams.get('city');
  const carType = searchParams.get('type') as IModel['model']['segment'] | null;

  useEffect(() => {
    if (!carList) return;

    const uniqueModelList = Array.from(
      new Map(carList.map((c) => [c.model.code, c])).values()
    );
    const models = uniqueModelList.map(({ model, modelName }) => {
      const carsPerModel = carList.filter((c) => c.model.code === model.code);
      const colorsPerModel = new Set(carsPerModel.map((c) => c.color)).size;

      return {
        code: model.code,
        name: modelName,
        cars: carsPerModel,
        colors: colorsPerModel,
        minPrice: Math.min(
          ...carsPerModel
            .filter((c) => Number(c.price))
            .map((c) => {
              return getPrice(c);
            })
        ),
        segment: model.segment || 'PC',
      };
    });

    setModels(models);
    setDealerList(
      Array.from(
        new Map(
          carList.map((c) => [
            c.dealer.bir,
            { title: c.dealer.name, ...c.dealer },
          ])
        ).values()
      )
    );
  }, [carList]);

  useEffect(() => {
    if (!models) return;

    setFilteredModels(
      models
        .map((m) => {
          let cars = [...m.cars];

          cars = cars
            .filter((c) => {
              return SedreStatus.inUkraine.includes(c.sedreStatus.status);
            })
            .filter((c) => {
              return city ? getCityName(c) === city : true;
            })
            .filter((c) => {
              return carType
                ? carType === 'PC'
                  ? isPC(c.model)
                  : isLCV(c.model)
                : true;
            });

          return {
            ...m,
            cars,
            colors: new Set(cars.map((c) => c.color)).size,
            minPrice: Math.min(
              ...cars
                .filter((c) => Number(c.price))
                .map((c) => {
                  return getPrice(c);
                })
            ),
          };
        })
        .filter((m) => m.cars.length)
        .sort((a, b) => a.minPrice - b.minPrice)
    );
  }, [city, carType, models]);

  useEffect(() => {
    if (!carList) return;

    pushGAToParentPage({
      event: 'virtualPageview',
      businessType: 'transverse',
      pageName: 'stock-home',
      pageType: 'stock-home',
    });
  }, [carList]);

  const cityOptions = useMemo(() => {
    const cities = Array.from(
      new Set(dealerList.map((d) => d.city.trim() || d.name))
    ).sort(sortCities);

    return cities.map((city) => {
      const count = carList?.filter((c) => {
        if (carType && !(carType === 'PC' ? isPC(c.model) : isLCV(c.model)))
          return false;
        return getCityName(c) === city;
      }).length;

      return {
        label: city,
        value: city,
        count,
      };
    });
  }, [carList, dealerList, carType]);

  const selectCityHandler = (
    value: (typeof cityOptions)[0]['value'] | null
  ) => {
    if (value) {
      searchParams.set('city', value);
    } else {
      searchParams.delete('city');
    }
    setSearchParams(searchParams);
  };

  if (error) return <>Something went wrong</>;

  if (isLoading) return <Loader />;

  const type = searchParams.get('type');

  const pcModels = filteredModels.filter(isPC);
  const lcvModels = filteredModels.filter(isLCV);
  const PCAmount = pcModels.reduce((prev, cur) => {
    return prev + cur.cars.length;
  }, 0);
  const LCVAmount = lcvModels.reduce((prev, cur) => {
    return prev + cur.cars.length;
  }, 0);
  const carsTotal = PCAmount + LCVAmount;

  return (
    <>
      <Meta
        title="Онлайн склад нових автомобілів Renault"
        description="Автомобілі Renault наявні на складі офіційного імпортера Renault та в салонах дилерів в регіонах України"
      />
      {!isDealerSite && (
        <Header
          action={{
            text: 'Головна сторінка',
            url: '//renault.ua',
          }}
        />
      )}
      <Row className="d-none d-md-block">
        <Banner
          data={[
            {
              img: '/assets/images/banner/all_cars1.jpg',
              links: [
                // {
                //   title: 'дізнайтеся більше',
                //   linkProps: {
                //     to: '//renault.ua',
                //     target: '_blank',
                //     rel: 'noreferrer',
                //   },
                // },
              ],
            },
          ]}
        />
      </Row>
      <div>
        {' '}
        <Row
          className={`${styles.topPanel} py-3 sticky-top`}
          style={{ top: -1 }}
        >
          <Col xs={12} md={4} className="d-flex align-items-center">
            <Label className={styles.countLabel + ' me-2'}>{carsTotal}</Label>
            Нових авто
          </Col>
          <Col
            xs={12}
            md={8}
            lg={{
              offset: 1,
              span: 7,
            }}
            xl={{
              offset: 3,
              span: 5,
            }}
            className="mt-3 mt-md-0"
          >
            <Row>
              <Col
                xs={12}
                sm={{
                  offset: 0,
                  span: 12,
                }}
                md={{
                  offset: 0,
                  span: 6,
                }}
              >
                <RenaultSelect
                  clearable
                  searchable
                  data={[
                    {
                      label: 'Легкові',
                      value: 'PC',
                    },
                    {
                      label: 'Комерційні',
                      value: 'LCV',
                    },
                  ]}
                  value={searchParams.get('type')}
                  onChange={(type) => {
                    if (type) {
                      searchParams.set('type', type);
                    } else {
                      searchParams.delete('type');
                    }
                    setSearchParams(searchParams);
                  }}
                  filter={undefined}
                  placeholder="Тип авто"
                  darkBg
                  itemComponent={forwardRef<HTMLDivElement, any>(
                    ({ label, value, ...others }: any, ref) => (
                      <div ref={ref} {...others}>
                        {label}
                      </div>
                    )
                  )}
                />
              </Col>
              <Col
                xs={12}
                sm={{
                  offset: 0,
                  span: 12,
                }}
                md={{
                  offset: 0,
                  span: 6,
                }}
                className="mt-2 mt-md-0"
              >
                <RenaultSelect
                  clearable
                  searchable
                  data={cityOptions}
                  value={
                    cityOptions.find(
                      ({ value }) => value === searchParams.get('city')
                    )?.value || null
                  }
                  onChange={selectCityHandler}
                  filter={undefined}
                  placeholder="Вся Україна"
                  darkBg
                  itemComponent={forwardRef<HTMLDivElement, any>(
                    ({ label, count, value, ...others }: any, ref) => (
                      <div ref={ref} {...others}>
                        <div className="d-flex">
                          <div className="me-3">
                            {value === FakeBir.inProduction ? (
                              <IoConstruct color="#656666" />
                            ) : value === FakeBir.onTheWay ? (
                              <IoCarSportSharp color="#656666" />
                            ) : value === FakeBir.importCenter ? (
                              <FaWarehouse color="#656666" />
                            ) : (
                              <FaMapMarkerAlt color="#656666" />
                            )}
                          </div>
                          <div className="flex-grow-1">{label}</div>
                          <div
                            className="ps-3 pe-1"
                            style={{
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {count}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row as="section" className="mt-4 mb-4">
          <Col>
            {(!type || type === 'PC') && (
              <>
                {!carType && !pcModels?.length ? (
                  <></>
                ) : (
                  <>
                    <Row>
                      <Col className={styles.carType}>
                        ЛЕГКОВІ АВТО
                        <Label className={styles.countLabel}>{PCAmount}</Label>
                      </Col>
                    </Row>
                    <Row
                      xs={2}
                      sm={2}
                      md={3}
                      lg={4}
                      xl={5}
                      className="g-1 mt-2"
                    >
                      {pcModels?.map((model) => (
                        <Col key={model.code}>
                          <CarShortCard
                            carsAmount={model.cars.length}
                            colorsAmount={model.colors}
                            modelCode={model.code}
                            modelName={model.name}
                            minPrice={model.minPrice}
                          />
                        </Col>
                      ))}
                    </Row>
                  </>
                )}
              </>
            )}
            {(!type || type === 'LCV') && (
              <>
                {!carType && !lcvModels?.length ? (
                  <></>
                ) : (
                  <>
                    <Row>
                      <Col className={styles.carType}>
                        КОМЕРЦІЙНІ АВТО
                        <Label className={styles.countLabel}>{LCVAmount}</Label>
                      </Col>
                    </Row>
                    <Row
                      xs={2}
                      sm={2}
                      md={3}
                      lg={4}
                      xl={5}
                      className="g-0 mt-2"
                    >
                      {lcvModels?.map((model) => (
                        <Col key={model.code}>
                          <CarShortCard
                            carsAmount={model.cars.length}
                            colorsAmount={model.colors}
                            modelCode={model.code}
                            modelName={model.name}
                            minPrice={model.minPrice}
                          />
                        </Col>
                      ))}
                    </Row>
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </div>
      <Info className="mt-3">
        <>
          <p>
            Наведена інформація в онлайн-складі про комплектації, технічні
            характеристики, рекомендовані клієнтські ціни тощо не є пропозицією
            укласти договір (офертою).
          </p>
          <p>
            Зовнішній вигляд автомобіля може відрізнятись від зображеного в
            онлайн-складі, що може бути версією автомобіля для інших ринків.
            Автомобіль на зображенні не обов'язково відповідає конкретним
            моделям, варіантам комплектації або пропозиціям. Деякі зображені
            елементи можуть бути недоступні або доступні тільки в певній
            комплектації чи у якості аксесуарів.
          </p>
          <p>
            *Вказані ціни є рекомендованими роздрібними (клієнтськими) цінами та
            не включають вартість додаткового обладнання, аксесуарів, що можуть
            бути встановлені за окремим замовленням, послуг, спеціальних акцій і
            пропозицій, які можуть діяти на момент придбання автомобіля у
            дилерів Renault в Україні. Роздрібна ціна автомобіля встановлюється
            дилерами Renault самостійно та може відрізнятись від зазначеної
            рекомендованої клієнтської ціни. Для отримання інформації по
            встановленій дилерами остаточній роздрібній ціні звертайтеся до
            офіційних дилерів Renault в Україні. АТ "Рено Україна" залишає за
            собою право змінювати рекомендовану роздрібну ціну шляхом
            публікування інформації про такі ціни на своєму веб-сайті.
          </p>
          <p>
            *Вказана найменша рекомендована ціна серед автомобілів, наявних в
            онлайн складі, з урахуванням їх фактичних комплектацій та
            додаткового обладнання.
          </p>
          <p>
            *Спеціальна ціна за умовами придбання в кредит за програмою «Renault
            Бонус» від Mobilize Financial Services для фізичних осіб. Кредитні
            умови за програмою «Renault Бонус»: максимальний строк кредиту – 60
            місяці, розмір першого внеску – від 20% до 50% (максимальний) від
            вартості автомобіля, максимальна сума кредиту – 2 600 000 грн.,
            процентна ставка – 12,9 % річних, комісія за видачу кредиту – 2,9 %,
            комісія за операції в Державному реєстрі обтяжень рухомого майна –
            750 грн. Реальна річна процентна ставка становить 35,3 % річних
            (розрахована виходячи з максимальної суми кредиту та максимального
            строку користування кредитом). Кредит надається у відділеннях Банку
            на всій території України крім тимчасово окупованих територій та
            територій, на яких державні органи України тимчасово не здійснюють
            свої повноваження (території, що перебувають в оточенні (блокуванні)
            та/або території на яких тривають активні бойові дії), внаслідок
            збройної агресії Російської федерації проти України розпочатої 24
            лютого 2022 року. Не кредитуються області (якщо клієнт проживає
            та/або отримує дохід від бізнесу в цих областях): Луганську,
            Донецьку, Херсонську, Запорізьку, АРК. З метою отримання інформації
            щодо оформлення кредиту звертайтеся за телефонами гарячої лінії
            0800305555 або на сайті банку www.credit-agricole.ua. Кредитує АТ
            «КРЕДІ АГРІКОЛЬ БАНК». Ліцензія НБУ №99 від 12.10.2011.
            Реєстраційний № 149 у Державному реєстрі банків. Банк залишає за
            собою право змінювати умови кредитування. Детальні умови
            кредитування на сайті www.credit-agricole.ua. Замовником реклами
            кредитних послуг є АТ «КРЕДІ АГРІКОЛЬ БАНК». Для отримання кредиту
            згідно з програмою «Renault Бонус» обов'язково придбання наступних
            послуг: страхування КАСКО та ОСЦПВ (надає СК «ARX»: Ліцензія
            Держфінпослуг серія АВ №483293 від 17.09.2009 р., Ліцензія
            Держфінпослуг серія АГ №569137 від 24.12.2010 р.; СК «УНІКА»:
            Ліцензія Держфінпослуг серія АЕ №293990 від 07.08.2014 р., Ліцензія
            Держфінпослуг серія АЕ №293760 від 21.05.2014 р.; СК «Українська
            страхова група»: Ліцензія Держфінпослуг серія АГ № 569295 від
            21.01.2011 р., Ліцензія Держфінпослуг серія АВ №500320 від
            13.01.2010 р.; СК «ПЗУ УКРАЇНА»: Ліцензія Держфінпослуг серія АВ
            №500102 від 15.12.2009 р., Ліцензія Держфінпослуг серія АГ №569236
            від 14.01.2011 р.) та страхування життя і здоров'я позичальника
            (надає СК «УНІКА»: Ліцензія Держфінпослуг серія АЕ №293990 від
            07.08.2014 р., Ліцензія Держфінпослуг серія АЕ №293760 від
            21.05.2014 р.)
          </p>
          <p>
            **Спеціальна ціна за умовами придбання в кредит за програмою
            «Renault Бонус» від Mobilize Financial Services для фізичних осіб.
            Кредитні умови за програмою «Renault Бонус»: максимальний строк
            кредиту – 60 місяці, розмір першого внеску – від 20% до 50%
            (максимальний) від вартості автомобіля, максимальна сума кредиту – 2
            600 000 грн., процентна ставка – 12,9 % річних, комісія за видачу
            кредиту – 2,9 %, комісія за операції в Державному реєстрі обтяжень
            рухомого майна – 750 грн. Реальна річна процентна ставка становить
            37,7 % річних (розрахована виходячи з максимальної суми кредиту та
            максимального строку користування кредитом). Кредит надається у
            відділеннях Банку на всій території України крім тимчасово
            окупованих територій та територій, на яких державні органи України
            тимчасово не здійснюють свої повноваження (території, що перебувають
            в оточенні (блокуванні) та/або території на яких тривають активні
            бойові дії), внаслідок збройної агресії Російської федерації проти
            України розпочатої 24 лютого 2022 року. Не кредитуються області
            (якщо клієнт проживає та/або отримує дохід від бізнесу в цих
            областях): Луганську, Донецьку, Херсонську, Запорізьку, АРК. З метою
            отримання інформації щодо оформлення кредиту звертайтеся за
            телефонами гарячої лінії 0800305555 або на сайті банку
            www.credit-agricole.ua. Кредитує АТ «КРЕДІ АГРІКОЛЬ БАНК». Ліцензія
            НБУ №99 від 12.10.2011. Реєстраційний № 149 у Державному реєстрі
            банків. Банк залишає за собою право змінювати умови кредитування.
            Детальні умови кредитування на сайті www.credit-agricole.ua.
            Замовником реклами кредитних послуг є АТ «КРЕДІ АГРІКОЛЬ БАНК». Для
            отримання кредиту згідно з програмою «Renault Бонус» обов'язково
            придбання наступних послуг: страхування КАСКО та ОСЦПВ (надає СК
            «ARX»: Ліцензія Держфінпослуг серія АВ №483293 від 17.09.2009 р.,
            Ліцензія Держфінпослуг серія АГ №569137 від 24.12.2010 р.; СК
            «УНІКА»: Ліцензія Держфінпослуг серія АЕ №293990 від 07.08.2014 р.,
            Ліцензія Держфінпослуг серія АЕ №293760 від 21.05.2014 р.; СК
            «Українська страхова група»: Ліцензія Держфінпослуг серія АГ №
            569295 від 21.01.2011 р., Ліцензія Держфінпослуг серія АВ №500320
            від 13.01.2010 р.; СК «ПЗУ УКРАЇНА»: Ліцензія Держфінпослуг серія АВ
            №500102 від 15.12.2009 р., Ліцензія Держфінпослуг серія АГ №569236
            від 14.01.2011 р.) та страхування життя і здоров'я позичальника
            (надає СК «УНІКА»: Ліцензія Держфінпослуг серія АЕ №293990 від
            07.08.2014 р., Ліцензія Держфінпослуг серія АЕ №293760 від
            21.05.2014 р.)
          </p>
          <p>
            Терміни «На складі», «В дорозі», «Дилер» - означають
            місцезнаходження автомобіля станом на дату оновлення даних он-лайн
            складу, а саме: на складі імпортера в Україні, у процесі
            транспортування до складу імпортера, на складі у дилера, відповідно.
            Щодо автомобілів «На складі», «В дорозі» всі деталі замовлення та
            доставки можливо уточнювати в обраного споживачем дилера офіційної
            дилерської мереж Рено. Щодо деталей замовлення автомобілів зі
            статусом «Дилер» необхідно звертатись безпосередньо до вказаного
            дилера.
          </p>
          <p>
            Наведена інформація в онлайн складі може бути не остаточною і
            підлягає уточненню у дилера Renault в Україні.
          </p>
          <p>
            Гаряча лінія Renault:
            <a className="px-1" href="tel: 0 800 500 351">
              0-800-500-351
            </a>
            (усі дзвінки в межах України зі стаціонарних і мобільних номерів
            українських операторів зв’язку – безкоштовні). Перелік дилерів, що
            здійснюють свою діяльність, зазначено на сайті
            <a
              className="px-1"
              href="//www.renault.ua"
              target="_blank"
              rel="noreferrer"
            >
              www.renault.ua.
            </a>
            Режим роботи обраного дилерського центру необхідно уточнювати
            безпосередньо в такого дилера.
          </p>
        </>
      </Info>
      {!isDealerSite && <Footer />}
    </>
  );
}

export default Models;
