import classNames from 'classnames';
import { ElementType } from 'react';
import styles from './r-input.module.scss';

type PolymorphicAsProp<E extends ElementType> = {
  as?: E;
};

interface IProps<T extends React.ElementType> {
  as?: T;
  success?: boolean;
  error?: boolean;
  isRequired?: boolean;
  errMessage?: string;
  label?: string;
  mask?: string;
}

export function RInput<T extends React.ElementType = 'input'>(
  props: IProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof IProps<T>>
) {
  const {
    error,
    success,
    isRequired,
    type,
    className,
    errMessage,
    placeholder,
    onChange,
    label,
    id,
    as: Cmp = 'input',
    ...rest
  } = props;

  const inputClassnames = classNames([styles.input], {
    [styles.inputFilled]: success,
    [styles.inputError]: error,
  });

  return (
    <div className={`${inputClassnames} ${className}`}>
      {label ? (
        <label htmlFor={id}>
          <Cmp
            id={id}
            type={type}
            placeholder={' '}
            onChange={onChange}
            {...rest}
          />
          <span>
            {label}
            {isRequired ? ' *' : ''}
          </span>
        </label>
      ) : (
        <Cmp
          id={id}
          type={type}
          placeholder={placeholder && `${placeholder}${isRequired ? ' *' : ''}`}
          onChange={onChange}
          {...rest}
        />
      )}

      {error && errMessage && (
        <div className={styles.errMessage}>{errMessage}</div>
      )}
    </div>
  );
}

export default RInput;
