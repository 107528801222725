import { useMemo } from 'react';
import { IDealer, IModel } from '../types';
import { useFilter } from './useFilter';
import { getCityName } from '../helpers/get-city-name';
import { sortCities } from '../helpers/sort-cities';
import { useSearchParams } from 'react-router-dom';

export const useFilteredData = (cars: IModel[]) => {
  const { filter } = useFilter(cars);
  const [searchParams] = useSearchParams();

  return useMemo(() => {
    const sortDealers = (a: IDealer, b: IDealer) => {
      const max = Number.MAX_VALUE;
      const orderCompare = (a.order || max) - (b.order || max);
      return orderCompare || a.city.localeCompare(b.city);
    };

    const cityCars = filter({ skip: ['city'] });
    const cityList = Array.from(new Set(cityCars.map(getCityName)))
      .sort(sortCities)
      .map((city) => ({
        city,
        carAmount: cityCars.filter((c) => getCityName(c) === city).length,
      }));

    const dealerCars = filter({ skip: ['dealer'] });
    const dealerList = Array.from(
      new Map(dealerCars.map((c) => [c.dealer.bir, c.dealer]))
    )
      .map(([bir, dealer]) => ({
        ...dealer,
        carAmount: dealerCars.filter((c) => c.dealer.bir === bir).length,
      }))
      .sort(sortDealers);

    const colorCars = filter({ skip: ['color'] });
    const colorList = Array.from(
      new Map(colorCars.map((c) => [c.color, c]))
    ).map(([colorCode, car]) => {
      const firstCar = car.version.colors.find((c) => c.code === colorCode)!;
      return {
        ...car,
        colorCode,
        colorName: firstCar.name,
        colorAmount: colorCars.filter((c) => c.color === colorCode).length,
        colorsHex: firstCar.hex,
      };
    });

    const fuelTypeCars = filter({ skip: ['fuelType'] });
    const fuelTypeList = Array.from(
      new Map(fuelTypeCars.map((c) => [c.version.fuelType, c]))
    ).map(([fuelType, car]) => ({
      ...car,
      fuelType,
      fuelTypeAmount: fuelTypeCars.filter(
        (c) => c.version.fuelType === fuelType
      ).length,
    }));

    const gearboxTypeCars = filter({ skip: ['gearbox'] });
    const gearboxTypeList = Array.from(
      new Map(gearboxTypeCars.map((c) => [c.version.gearbox, c]))
    ).map(([gearbox, car]) => ({
      ...car,
      gearbox,
      gearboxTypeAmount: gearboxTypeCars.filter(
        (c) => c.version.gearbox === gearbox
      ).length,
    }));

    const powerHPCars = filter({ skip: ['powerHP'] });
    const powerHPList = Array.from(
      new Map(powerHPCars.map((c) => [c.version.powerHP, c]))
    ).map(([powerHP, car]) => ({
      ...car,
      powerHP,
      powerHPAmount: powerHPCars.filter((c) => c.version.powerHP === powerHP)
        .length,
    }));

    const driveCars = filter({ skip: ['drive'] });
    const driveList = Array.from(
      new Map(driveCars.map((c) => [c.version.drive, c]))
    ).map(([drive, car]) => ({
      ...car,
      drive,
      driveAmount: driveCars.filter((c) => c.version.drive === drive).length,
    }));

    const yearCars = filter({ skip: ['year'] });
    const yearList = Array.from(
      new Map(
        yearCars.map((c) => [
          new Date(c.releaseYear).getFullYear().toString(),
          c,
        ])
      )
    ).map(([year, car]) => ({
      ...car,
      year,
      yearAmount: yearCars.filter(
        (c) => new Date(c.releaseYear).getFullYear().toString() === year
      ).length,
    }));

    const eqpLevelCars = filter({ skip: ['eqpLevel'] });
    const eqpLevelList = Array.from(
      new Map(eqpLevelCars.map((c) => [c.version.equipmentName, c]))
    )
      .sort(
        ([, a], [, b]) =>
          a.version.equipmentNameOrder - b.version.equipmentNameOrder
      )
      .map(([eqpLevel, car]) => {
        return {
          ...car,
          eqpLevel,
          eqpLevelAmount: eqpLevelCars.filter(
            (c) => c.version.equipmentName === eqpLevel
          ).length,
        };
      });

    return {
      cityList,
      dealerList,
      colorList,
      fuelTypeList,
      gearboxTypeList,
      powerHPList,
      driveList,
      yearList,
      eqpLevelList,
    };
  }, [cars, searchParams.toString()]);
};
