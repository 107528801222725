import { useState } from 'react';
import { FaArrowCircleUp } from 'react-icons/fa';
import styles from './scroll-top.module.scss';
import { HiChevronDoubleUp } from 'react-icons/hi';

export const ScrollTop = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <button
      className={styles.Button}
      style={{ display: visible ? 'flex' : 'none' }}
      onClick={scrollToTop}
    >
      <HiChevronDoubleUp />
    </button>
  );
};
