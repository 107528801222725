import { Select, SelectProps } from '@mantine/core';

interface IRenaultSelectProps {
  darkBg?: boolean;
  customStyles?: SelectProps['styles'];
}

export function RenaultSelect({
  darkBg = false,
  customStyles = {},
  ...props
}: IRenaultSelectProps & SelectProps) {
  const defaultStyles: SelectProps['styles'] = {
    ...BaseStyles,
    ...(darkBg && DarkBgStyles),
  };

  const overrideStyles: SelectProps['styles'] = Object.keys(
    customStyles
  ).reduce((acc, key) => {
    acc[key] = {
      ...((defaultStyles as any)[key] || {}),
      ...(customStyles as any)[key],
    };
    return acc;
  }, {} as any);

  const styles = { ...defaultStyles, ...overrideStyles };

  return (
    <Select
      placeholder=""
      nothingFound="Нічого не знайдено"
      radius={0}
      styles={styles}
      {...props}
      lang="uk"
    />
  );
}

const BaseStyles: SelectProps['styles'] = {
  root: {
    width: 'auto',
  },
  wrapper: {},
  input: {
    fontFamily: 'NouvelR !important',
    border: '1px solid #D9D9D6',
    fontSize: 15,
    height: 39,
    '&:hover': {
      border: '1px solid #8C8C8B',
    },
    '&:focus': {
      border: '1px solid #656666',
    },
    '&::placeholder': {
      color: '#000',
    },
    '&[data-invalid=true]': {
      borderColor: '#b21212',
      color: '#b21212',
      '&::placeholder': {
        color: '#b21212',
      },
    },
  },
  item: {
    fontFamily: 'NouvelR !important',
    '&[data-selected]': {
      '&, &:hover': {
        backgroundColor: '#EFDF00',
        color: '#000',
        fontWeight: 600,
      },
    },
    borderRadius: 0,
    wordBreak: 'break-word',
  },
  itemsWrapper: {
    padding: 0,
  },
  error: {
    color: '#b21212',
    fontSize: 13,
  },
  icon: {
    color: '#000',
  },
};

const DarkBgStyles: SelectProps['styles'] = {
  ...BaseStyles,
  wrapper: {
    ...BaseStyles?.wrapper,
    '&:hover': {
      '*': {
        color: '#fff',
      },
      'input::placeholder': {
        color: '#fff',
      },
    },
  },
  input: {
    ...BaseStyles?.input,
    color: '#fff',
    backgroundColor: 'transparent',
    border: '1px solid #3E3F40',
    '&:focus': {
      border: '1px solid #8C8C8B',
    },
    '&::placeholder': {
      color: '#D9D9D6',
    },
  },
};
