interface IPriceProps {
  children: string | number;
  currency?: string;
  isHideCurrency?: boolean;
  round?: boolean;
}

export function Price({
  children,
  currency = 'грн*',
  isHideCurrency = false,
  round = true,
}: IPriceProps) {
  let value = `${children}`.replace(/\s+/, '');
  if (round) value = Math.ceil(Number(value)).toString();
  const suffix = currency && !isHideCurrency ? ` ${currency}` : '';
  return (
    <>
      {value.replace(/(?=(?:.{3})*$)/g, ' ')}
      {suffix}
    </>
  );
}
