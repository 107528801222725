import { Col, Row } from 'react-bootstrap';

export function Footer() {
  return (
    <Row
      className="mt-4 mb-4 px-2 gx-4 gy-2"
      style={{ fontSize: 13, fontFamily: 'NouvelR' }}
    >
      <Col sm="auto">
        <a href="https://www.renault.ua/personal-data.html">
          Обробка персональних даних
        </a>
      </Col>
      <Col sm="auto">
        <a href="https://www.renault.ua/terms-and-conditions.html">
          Юридична інформація
        </a>
      </Col>
      <Col sm="auto">
        <a href="https://www.renault.ua/cookies.html">Cookies</a>
      </Col>
      <Col>
        <div className="d-flex justify-content-md-end">
          <b>© Renault 2017 - {new Date().getFullYear()}</b>
        </div>
      </Col>
    </Row>
  );
}
