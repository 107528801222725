import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import {
  Form as BsForm,
  Col,
  Container,
  Modal,
  Nav,
  Row,
  Spinner,
  Tab,
  Table,
} from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { AiOutlineGlobal, AiOutlineInfoCircle } from 'react-icons/ai';
import { BsCalendar3, BsCode, BsFuelPump } from 'react-icons/bs';
import { FiPhone } from 'react-icons/fi';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { TbManualGearbox } from 'react-icons/tb';
import { IMaskInput } from 'react-imask';
import { Carousel } from 'react-responsive-carousel';
import { Link, useParams } from 'react-router-dom';
import useSWRImmutable from 'swr/immutable';
import * as Yup from 'yup';
import { api, fetcher, getCarList, getDealerList } from '../api';
import { Button } from '../components/button';
import { Info } from '../components/info';
import { Loader } from '../components/loader';
import { GMap, Marker } from '../components/map';
import { Price } from '../components/price';
import RInput from '../components/r-input';
import { RenaultSelect } from '../components/r-select';
import { FakeBirs, R_LINKS, SedreStatus } from '../constants';
import {
  getCarImgList,
  isFakeDealer,
  isFilled,
  isPresent,
  isSedreTypeEqual,
} from '../helpers';
import { ReactComponent as DieselIcon } from '../static/icons/diesel.svg';
import { ReactComponent as EngineIcon } from '../static/icons/engine_icon.svg';
import { ReactComponent as FrontWheelDrive } from '../static/icons/front-wheel-drive.svg';
import { ReactComponent as PetrolIcon } from '../static/icons/petrol.svg';
import { ReactComponent as ExteriorIcon } from '../static/icons/rdms-icon-exterior.svg';
import { ReactComponent as InteriorIcon } from '../static/icons/rdms-icon-interior.svg';
import { ReactComponent as YellowMarker } from '../static/icons/yellow-marker.svg';
import { IDealer, IModel } from '../types';
import { InfoPopover } from '../components/info-popover';
import {
  IEqpGroup,
  IEquipment,
  IEquipments,
  IEquipmentsResponse,
} from '../types/equipments';

import styles from './car-detail.module.scss';
import { formatEngine } from '../components/filters';
import { checkDealerSite } from '../helpers/check-dealer-site';
import { phoneNormalize } from '../helpers/phone-normalize';
import { Header } from '../components/header';
import { Footer } from '../components/footer';
import { Meta } from '../components/meta';

type IDealersResponse = { data: IDealer[] };

function CarDetail() {
  const [car, setCar] = useState<IModel>();
  const [showForm, setShowForm] = useState<'credit' | 'info' | null>(null);
  const params = useParams();
  const [result, setResult] = useState<any>();
  const { isDealerSite } = checkDealerSite();

  const {
    data: carList,
    error,
    isLoading,
  } = useSWRImmutable({ url: '/cars/list' }, getCarList);

  const { data: equipments } = useSWRImmutable(
    car ? { url: `/cars/version/equipments/${params.vin}` } : null,
    (params) =>
      fetcher<IEquipmentsResponse>(params).then(({ data }) => {
        return addOption(data);
      })
  );

  const { data: dealers } = useSWRImmutable(
    { url: `/dealers/list` },
    getDealerList
  );

  const formik = useFormik({
    onSubmit: (values, { setSubmitting }) => {
      console.log({ formik: values });
      const { name, dealer, phone, comment, sms } = values;
      api
        .post('/booking', {
          name,
          phone,
          dealerBIR: dealer,
          comment,
          carVin: params.vin,
          email: '',
          isSMSMarketing: sms,
          device: isMobile ? 'mobile' : 'desktop',
          subTypeInterest:
            showForm === 'credit' ? 'Finance request' : 'Purchase request',
          isDealerSite,
        })
        .then(setResult)
        .then(() => {
          const { eventLabel, requestType } = getGAdata();
          pushGAToParentPage({
            event: 'trackEvent',
            businessType: 'new-car',
            eventCategory: 'Leadform Validations',
            eventAction: 'LEADFORM',
            eventLabel,
            requestType,
            formCategory: 'LEADFORM',
            formType: 'stock',
            formName: 'stock-pop-up',
            formStepNumber: '1',
            vehicleModel: modelName,
            vehicleId: model.code,
            vehicleVersion: equipmentName,
            vehicleVersionID: vin,
          });
        })
        .catch((e: AxiosError) =>
          alert((e.response?.data as any)?.data?.message || e.message)
        )
        .finally(() => setSubmitting(false));
    },
    initialValues: {
      dealer: '',
      name: '',
      phone: '',
      email: '',
      comment: '',
      sms: true,
    },
    validationSchema: Yup.object({
      name: Yup.string().min(2, `Введіть ім'я`).required(`Введіть ім'я`),
      // email: Yup.string().matches(
      //   /.+@.+\.[a-zA-Z]{2,}/i,
      //   'Введіть електронну пошту'
      // ),
      phone: Yup.string()
        .min(19, 'Введіть номер телефону')
        .required('Введіть номер телефону'),
      dealer: Yup.string().min(8, 'Оберіть дилера').required('Оберіть дилера'),
    }),
  });

  useEffect(() => {
    if (!carList) return;
    const car = carList.find((c) => params.vin === c.vin);
    if (!car) return;
    setCar(car);

    if (!formik.values.dealer) {
      if (!car) return;
      const { dealer, sedreStatus } = car;
      const bir =
        dealer && SedreStatus.inUkraine.includes(sedreStatus.status)
          ? dealer.bir
          : '';
      if (!FakeBirs.includes(bir)) {
        formik.setFieldValue('dealer', bir);
      }
    }
  }, [carList, params]);

  useEffect(() => {
    if (!dealers) return;
    if (!formik.values.dealer && dealers.length === 1) {
      formik.setFieldValue('dealer', dealers[0].bir);
    }
  }, [dealers]);

  useEffect(() => {
    if (!car) return;
    pushGAToParentPage({
      event: 'virtualPageview',
      businessType: 'new-car',
      pageType: 'stock-car',
      vehicleModel: modelName,
      vehicleId: model.code,
      vehicleVersion: equipmentName,
      vehicleVersionID: vin,
    });

    const isCarInDealer =
      !isFakeDealer(car.dealer) &&
      isSedreTypeEqual('inUkraine', car.sedreStatus.status);

    if (isCarInDealer) {
      pushToParentPage({
        type: 'updateDealerBir',
        data: car.dealer.bir,
      });
    }
  }, [car]);

  useEffect(() => {
    if (!showForm) return;
    const { eventLabel, requestType } = getGAdata();
    pushGAToParentPage({
      event: 'trackEvent',
      businessType: 'new-car',
      eventCategory: 'Leadform Opening',
      eventAction: 'LEADFORM',
      eventLabel,
      requestType,
      formCategory: 'LEADFORM',
      formType: 'stock',
      formName: 'stock-pop-up',
      formStepNumber: '0',
      formStepName: 'залишити запис',
      vehicleModel: modelName,
      vehicleId: model.code,
      vehicleVersion: equipmentName,
      vehicleVersionID: vin,
    });
  }, [showForm]);

  const getGAdata = () => {
    return {
      eventLabel:
        showForm === 'info' ? 'Залишити Запит' : 'Отримати розрахунок кредиту',
      requestType: showForm === 'info' ? 'info' : 'credit',
    };
  };

  const addOption = (equipments: IEquipments): IEquipments => {
    if (!car) return equipments;

    const INTERIOR_GROUP = 'ЕЛЕМЕНТИ ВНУТРІШНЬОГО ДИЗАЙНУ';

    const addHandler = (eqp: IEqpGroup[], opt: string) => {
      const check = eqp.find((e) => e.group === INTERIOR_GROUP);

      const newEqp = {
        name: opt,
        id: new Date().toString(),
      } as IEquipment;

      if (!check) {
        eqp.push({
          group: INTERIOR_GROUP,
          equipments: [newEqp],
        });
      } else {
        if (!check.equipments.find((e) => e.name === opt)) {
          check.equipments.push(newEqp);
        }
      }
    };

    const add = car.drap || car.cuir;
    if (add) {
      addHandler(equipments.additional, add);
      return equipments;
    }

    const main = car.version.drap || car.version.cuir;
    if (main) {
      addHandler(equipments.main, main);
      return equipments;
    }

    return equipments;
  };

  if (error) return <>Something went wrong</>;

  if (isLoading || !car) return <Loader />;

  const isCarInDealer =
    !isFakeDealer(car.dealer) &&
    isSedreTypeEqual('inUkraine', car.sedreStatus.status);

  const isCarInImportCenter =
    !isCarInDealer && isSedreTypeEqual('importCenter', car.sedreStatus.status);

  const getCoordinates = () => {
    if (!isCarInDealer || !car.dealer.coordinates?.trim()) {
      return null;
    }

    return {
      lat: Number(car.dealer.coordinates.split(',')[0]),
      lng: Number(car.dealer.coordinates.split(',')[1]),
    };
  };

  const coordinates = getCoordinates();

  const {
    version,
    dealer,
    model,
    modelName,
    price: basePrice,
    releaseYear,
    vin,
    color,
    discount,
    consumption,
    emission_co2,
  } = car;
  const {
    engineCapacity,
    powerHP,
    gearboxType,
    versionName,
    fuelType,
    colors,
    drive,
    equipmentName,
  } = version;

  const drap = car.drap || car.version.drap;
  const cuir = car.cuir || car.version.cuir;
  const colorsData = colors.find((c) => c.code === color) || colors[0];
  const engine = formatEngine(engineCapacity, powerHP);

  const imgList = getCarImgList(
    model.code as any,
    equipmentName,
    color,
    version.version,
    equipments?.additional
  );

  const promo0 = car.model.promo?.find((p) => p.type === '0' && +p.value > 0);
  const price = discount && discount.price ? discount.price : basePrice;
  const creditPrice = promo0 && Number(price) - Number(promo0.value);

  // console.log({ imgList });

  return (
    <>
      <Meta
        title={`${modelName} ${versionName} — наявність на складі Renault в Україні`}
        description={`${modelName} ${versionName} — онлайн склад нових автомобілів Renault`}
      />
      {!isDealerSite && (
        <Header
          action={
            car
              ? {
                  text: `Детальніше ${car.modelName}`,
                  url: R_LINKS.details[car.model.code],
                }
              : undefined
          }
        />
      )}
      <Container fluid className="pb-3">
        <Row>
          <Col className="mt-3 mt-md-2">
            <Link to={`/`}>
              <Button variant="primary-link" className="ps-0 py-0 py-md-2">
                Всі моделі
              </Button>
            </Link>
            <Link to={`/${model.code}`}>
              <Button variant="primary-link" className="ps-0 py-0 py-md-2">
                {modelName}
              </Button>
            </Link>
            <Button variant="primary-link" active className="ps-0 py-0 py-md-2">
              {versionName}
            </Button>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={12} md={7} lg={7}>
            <Carousel
              className={`p-0 carousel-resp`}
              useKeyboardArrows
              infiniteLoop
              // autoPlay
              stopOnHover
              showStatus={false}
              showIndicators={true}
              showThumbs={false}
              emulateTouch
            >
              {imgList?.map((src, i) => {
                return (
                  <div key={i}>
                    <img src={src} alt="" className={styles.img} />
                  </div>
                );
              })}
            </Carousel>
          </Col>
          <Col xs={12} md={5} lg={5} className="text-center text-md-start">
            <div className={[styles.modelName, 'd-none d-md-block'].join(' ')}>
              {modelName}
            </div>
            <div
              className={[styles.versionGroup, 'd-none d-md-block'].join(' ')}
            >
              {equipmentName}
            </div>
            <div className={styles.status + ' mt-3 mt-md-4 mt-lg-4'}>
              {isCarInDealer ? (
                <div className={styles.dealerInfo}>
                  <div>В наявності</div>
                  <div
                    className="mt-3"
                    style={{ fontSize: 20, fontWeight: 600 }}
                  >{`${dealer.city}`}</div>

                  <Info
                    id="dealer_contact"
                    title="показати контакти"
                    className={`mt-2 ${styles.dealerContact}`}
                    defaultActiveKey={''}
                    children={
                      <>
                        <div>{`${dealer.name}`}</div>
                        {dealer.phone && (
                          <a href={`tel:${phoneNormalize(dealer.phone)}`}>
                            {dealer.phone}
                          </a>
                        )}
                        {dealer.site && (
                          <div>
                            <a
                              href={dealer.site}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {dealer.site.split('//')[1]?.replace('/', '')}
                            </a>
                          </div>
                        )}
                      </>
                    }
                  />
                </div>
              ) : (
                <>
                  {isCarInImportCenter && (
                    <div className="mb-4">В наявності</div>
                  )}
                  <span>
                    {dealer.name}
                    <InfoPopover
                      triggerComponent={
                        <AiOutlineInfoCircle className="ms-1" />
                      }
                      children={
                        <a
                          href="#"
                          className={styles.link}
                          onClick={() => setShowForm('info')}
                        >
                          Про можливість доставки дізнавайтесь у найближчого
                          дилера
                        </a>
                      }
                    />
                  </span>
                  <div>
                    <a href={`tel:${phoneNormalize('0 800 500 351')}`}>
                      0 800 500 351
                    </a>
                    <div>
                      <small>Служба підтримки клієнтів Renault</small>
                    </div>
                  </div>
                </>
              )}
            </div>
            {discount && (
              <div className="mt-3">
                <span className={styles.discount}>акційна ціна**</span>
              </div>
            )}
            <div
              className={`${styles.price} mt-3 d-flex flex-column justify-content-center align-items-center mt-xl-4 flex-xl-row justify-content-xl-between`}
            >
              <b>
                <Price currency="грн">{price}</Price>
                <InfoPopover
                  triggerComponent={
                    <AiOutlineInfoCircle className="ms-1 mb-4" size={16} />
                  }
                  children={
                    <>
                      {discount
                        ? 'Рекомендована акційна ціна'
                        : 'Рекомендована роздрібна (клієнтська) ціна'}
                    </>
                  }
                />
              </b>
              <Button
                variant="super-primary"
                className="text-center"
                style={{
                  fontSize: 18,
                }}
                onClick={() => setShowForm('info')}
              >
                зв'язатися з дилером
              </Button>
            </div>
            {!!creditPrice && (
              <div className="mt-3 d-flex flex-column justify-content-center align-items-center flex-xl-row justify-content-xl-between">
                <b style={{ fontSize: 20 }}>
                  <Price>{creditPrice}</Price>
                  <InfoPopover
                    triggerComponent={
                      <AiOutlineInfoCircle className="ms-1 mb-4" size={16} />
                    }
                    children={
                      <>
                        Ціна з врахуванням знижки за кредитною програмою Renault
                        Бонус
                      </>
                    }
                  />
                </b>
                <Button
                  variant="secondary"
                  className="text-center"
                  style={{
                    fontSize: 18,
                  }}
                  onClick={() => setShowForm('credit')}
                >
                  отримати розрахунок кредиту
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <Row className={styles.mainInfo + ' mt-4 mt-md-0 px-4 py-3'}>
        <Col xs={12} lg={4}>
          <Row className="">
            <Col xs={12} className="py-2 py-md-2">
              <div className="d-flex align-items-center">
                <ExteriorIcon
                  height={24}
                  width={24}
                  className="flex-shrink-0"
                />
                <div className="flex-grow-1 ps-3">
                  <div>Колір кузова</div>
                  <em>{colorsData.name}</em>
                </div>
              </div>
            </Col>
            <Col xs={12} className="py-2 py-md-2">
              <div className="d-flex align-items-center">
                <InteriorIcon
                  height={24}
                  width={24}
                  className="flex-shrink-0"
                />
                <div className="flex-grow-1 ps-3">
                  <div>Салон</div>
                  <em>{[drap, cuir].filter(Boolean).join(', ') || '-'}</em>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={8}>
          <Row className="mt-3 mt-md-0">
            <Col xs={12} sm={6} md={4} className="py-2 py-md-2">
              <div className="d-flex align-items-center">
                <BsCalendar3 size={24} />
                <div className="flex-grow-1 ps-3">
                  <div>Piк виробництва</div>
                  <em>{new Date(releaseYear).getFullYear()}</em>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6} md={4} className="py-2 py-md-2">
              <div className="d-flex align-items-center">
                <BsCode size={26} />
                <div className="flex-grow-1 ps-3">
                  <div>VIN</div>
                  <em>{'**** ' + vin.substr(-4, 4)}</em>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6} md={4} className="py-2 py-md-2">
              <div className="d-flex align-items-center">
                <TbManualGearbox size={26} />
                <div className="flex-grow-1 ps-3">
                  <div>Коробка передач</div>
                  <em>{gearboxType}</em>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6} md={4} className="py-2 py-md-2">
              <div className="d-flex align-items-center">
                {fuelType === 'Бензин' ? (
                  <PetrolIcon height={24} width={24} />
                ) : fuelType === 'Дизель' ? (
                  <DieselIcon height={24} width={24} />
                ) : (
                  <BsFuelPump size={24} />
                )}
                <div className="flex-grow-1 ps-3">
                  <div>Пальне</div>
                  <em>{fuelType}</em>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6} md={4} className="py-2 py-md-2">
              <div className="d-flex align-items-center">
                <EngineIcon style={{ height: 26, width: 26 }} />
                <div className="flex-grow-1 ps-3">
                  <div>Двигун</div>
                  <em>{engine}</em>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6} md={4} className="py-2 py-md-2">
              <div className="d-flex align-items-center">
                <FrontWheelDrive style={{ height: 26, width: 26 }} />
                <div className="flex-grow-1 ps-3">
                  <div>Привод</div>
                  <em>{drive}</em>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-3" id="sum-tabs">
        <Col xs={12} lg={9}>
          <Tab.Container id="left-tabs-sum" defaultActiveKey="1">
            <Row>
              <Col xs={12} md={4} lg={4} xl={3}>
                <Nav variant="pills" className="flex-column left-sum-pills">
                  <Nav.Item>
                    <Nav.Link eventKey="1">Базова комплектація</Nav.Link>
                  </Nav.Item>
                  {!!equipments?.additional?.length && (
                    <Nav.Item>
                      <Nav.Link eventKey="2">Додаткові опції</Nav.Link>
                    </Nav.Item>
                  )}
                  <Nav.Item>
                    <Nav.Link eventKey="3">Технічні характеристики</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="4">Гарантія</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col
                className="mt-3 mt-md-0 p-3 "
                style={{ border: '1px solid #ddd' }}
              >
                <Tab.Content>
                  <Tab.Pane eventKey="1">
                    {equipments && (
                      <Tab.Container
                        id="eqp-tabs"
                        defaultActiveKey={equipments.main[0]?.group}
                      >
                        <Row>
                          <Col>
                            <Nav
                              variant="pills"
                              className="flex eqp-tabs-wrapper"
                            >
                              {equipments.main
                                .filter(
                                  (e) => e.group?.toLowerCase() !== 'гарантія'
                                )
                                .map(({ group }) => (
                                  <Nav.Item key={group}>
                                    <Nav.Link eventKey={group}>
                                      {group}
                                    </Nav.Link>
                                  </Nav.Item>
                                ))}
                            </Nav>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Tab.Content
                              className={['mt-3', styles.eqpContent].join(' ')}
                            >
                              {equipments.main
                                .filter(
                                  (e) => e.group?.toLowerCase() !== 'гарантія'
                                )
                                .map(({ group, equipments }) => (
                                  <Tab.Pane eventKey={group} key={group}>
                                    <ul>
                                      {equipments.map((eqp, i) => (
                                        <li key={eqp.id + i}>{eqp.name}</li>
                                      ))}
                                    </ul>
                                  </Tab.Pane>
                                ))}
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="2">
                    {equipments && (
                      <Tab.Container
                        id="add-eqp-tabs"
                        defaultActiveKey={equipments.additional[0]?.group}
                      >
                        <Row>
                          <Col>
                            <Nav
                              variant="pills"
                              className="flex eqp-tabs-wrapper"
                            >
                              {equipments.additional.map(({ group }) => (
                                <Nav.Item key={group}>
                                  <Nav.Link eventKey={group}>{group}</Nav.Link>
                                </Nav.Item>
                              ))}
                            </Nav>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Tab.Content
                              className={['mt-3', styles.eqpContent].join(' ')}
                            >
                              {equipments.additional.map(
                                ({ group, equipments }) => (
                                  <Tab.Pane eventKey={group} key={group}>
                                    <ul>
                                      {equipments.map((eqp, i) => (
                                        <li key={eqp.id + i}>{eqp.name}</li>
                                      ))}
                                    </ul>
                                  </Tab.Pane>
                                )
                              )}
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="3">
                    <b>Технічні характеристики</b>
                    <hr />
                    <Table
                      size="sm"
                      striped
                      hover
                      className={styles.specifications}
                    >
                      <tbody>
                        <tr>
                          <td>Потужність</td>
                          <td>{powerHP}</td>
                        </tr>
                        <tr>
                          <td>Об'єм двигуна</td>
                          <td>{engineCapacity}</td>
                        </tr>
                        <tr>
                          <td>Коробка передач</td>
                          <td>{gearboxType}</td>
                        </tr>
                        <tr>
                          <td>Пальне</td>
                          <td>{fuelType}</td>
                        </tr>
                        <tr>
                          <td>Витрати пального (заміський цикл)</td>
                          <td>{consumption?.highway || 'дані відсутні'}</td>
                        </tr>
                        <tr>
                          <td>Витрати пального (міський цикл)</td>
                          <td>{consumption?.city || 'дані відсутні'}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Tab.Pane>
                  <Tab.Pane eventKey="4">
                    <b>Гарантія</b>
                    <hr />
                    <div>
                      Стандартна гарантія на автомобілі складає 3 роки або 100
                      000 км пробігу (в залежності від того, що настане раніше).
                      Подовжена гарантія є додатковою опцією, вартість
                      подовженої гарантії сплачується в складі ціни за
                      автомобіль і не може бути сплачена окремо.
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Col>
        <Col xs={12} lg={3} className="mt-3 mt-lg-0">
          <div
            className="text-light text-center p-3 px-4"
            style={{ backgroundColor: '#000' }}
          >
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ lineHeight: 1 }}
            >
              <div
                style={{
                  paddingRight: 4,
                  textAlign: 'start',
                  maxWidth: '45%',
                  wordWrap: 'break-word',
                }}
              >
                Рекомендована ціна
              </div>
              <div style={{ fontSize: 26 }}>
                <Price>{price}</Price>
              </div>
            </div>
            <Button
              variant="super-primary"
              className="mt-3"
              style={{
                textTransform: 'uppercase',
                display: 'block',
                width: '100%',
                padding: '15px',
              }}
              onClick={() => setShowForm('info')}
            >
              зв'язатися з дилером
            </Button>
            {!!creditPrice && (
              <>
                <div
                  className="mt-3 d-flex justify-content-between align-items-center"
                  style={{ lineHeight: 1 }}
                >
                  <div style={{ paddingRight: 4, textAlign: 'start' }}>
                    Ціна в кредит
                  </div>
                  <div style={{ fontSize: 20 }}>
                    <Price>{creditPrice}</Price>
                  </div>
                </div>
                <Button
                  variant="secondary-inv"
                  className="text-center mt-2"
                  style={{
                    textTransform: 'uppercase',
                    display: 'block',
                    width: '100%',
                    padding: '15px',
                  }}
                  onClick={() => setShowForm('credit')}
                >
                  отримати розрахунок кредиту
                </Button>
              </>
            )}
            <div className="mt-4 text-start" style={{ fontSize: 12 }}>
              * всі ціни вказані з ПДВ
            </div>
          </div>
        </Col>
      </Row>
      {isCarInDealer && (
        <Row className={`${styles.location} mt-3 g-4`}>
          <Col md={6} lg={4} className="text-center">
            <div
              className="mt-0 mt-md-4 px-3"
              style={{ fontSize: 20, fontWeight: 600 }}
            >
              АВТОМОБІЛЬ В НАЯВНОСТІ
            </div>
            <a
              href={`https://www.google.com/maps/dir//${
                dealer.coordinates || '#'
              }`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="d-flex mt-3 align-items-center px-3">
                <HiOutlineLocationMarker size={22} />
                <div
                  className="ms-3 text-start"
                  style={{ lineHeight: 1.2, fontSize: 16 }}
                >
                  <div>{dealer.name}</div>
                  <div>{dealer.address}</div>
                </div>
              </div>
            </a>
            <div className="d-flex mt-3 align-items-center px-3">
              <FiPhone size={20} />
              <div className="ms-3 text-start" style={{ fontSize: 24 }}>
                <a href={`tel:${phoneNormalize(dealer.phone)}`}>
                  {dealer.phone}
                </a>
              </div>
            </div>
            <div className="d-flex mt-3 align-items-center px-3">
              <AiOutlineGlobal size={22} />
              <div className="ms-3 text-start" style={{ fontSize: 16 }}>
                <a href={dealer.site}>
                  {dealer.site?.split('//')[1]?.replace('/', '')}
                </a>
              </div>
            </div>
          </Col>
          <Col md={6} lg={8} className="px-0 mt-md-0">
            {coordinates && (
              <div style={{ height: '300px', width: '100%' }}>
                <GMap center={coordinates} zoom={14}>
                  <Marker
                    lat={coordinates.lat}
                    lng={coordinates.lng}
                    title={dealer.name}
                  >
                    <YellowMarker height={48} />
                  </Marker>
                </GMap>
              </div>
            )}
          </Col>
        </Row>
      )}
      <Info className="mt-3">
        <>
          <p>
            Наведена інформація в онлайн-складі про комплектації, технічні
            характеристики, рекомендовані клієнтські ціни тощо не є пропозицією
            укласти договір (офертою).
          </p>
          <p>
            Зовнішній вигляд автомобіля може відрізнятись від зображеного в
            онлайн-складі, що може бути версією автомобіля для інших ринків.
            Автомобіль на зображенні не обов'язково відповідає конкретним
            моделям, варіантам комплектації або пропозиціям. Деякі зображені
            елементи можуть бути недоступні або доступні тільки в певній
            комплектації чи у якості аксесуарів.
          </p>
          {discount ? (
            <p>
              **Під акційною ціною мається на увазі рекомендована клієнтська
              (роздрібна) ціна зі знижкою (відносно зазначеної звичайної
              рекомендованої клієнтської ціни). Рекомендована акційна ціна діє з{' '}
              {discount.startDate?.concat(' ') || '-'} року по{' '}
              {discount.endDate || '-'} року в офіційній мережі дилерів Renault
              на території Україні, за винятком тимчасово окупованих територій
              та зони проведення заходів із забезпечення оборони та протидії
              збройної агресії Російської федерації. Рекомендована акційна ціна
              діє при роздрібному продажі наявних на складі автомобілів.
              Остаточну роздрібну ціна та наявність автомобіля на складі
              уточнюйте у офіційних дилерів Renault.
            </p>
          ) : (
            <p>
              *Вказані ціни є рекомендованими роздрібними (клієнтськими) цінами
              та не включають вартість додаткового обладнання, аксесуарів, що
              можуть бути встановлені за окремим замовленням, послуг,
              спеціальних акцій і пропозицій, які можуть діяти на момент
              придбання автомобіля у дилерів Renault в Україні. Роздрібна ціна
              автомобіля встановлюється дилерами Renault самостійно та може
              відрізнятись від зазначеної рекомендованої клієнтської ціни. Для
              отримання інформації по встановленій дилерами остаточній
              роздрібній ціні звертайтеся до офіційних дилерів Renault в
              Україні. АТ "Рено Україна" залишає за собою право змінювати
              рекомендовану роздрібну ціну шляхом публікування інформації про
              такі ціни на своєму веб-сайті.
            </p>
          )}
          {['R3U', 'R3O', 'DF1'].includes(params.modelCode || '') ? (
            <p>
              *Спеціальна ціна за умовами придбання в кредит за програмою
              «Renault Бонус» від Mobilize Financial Services для фізичних осіб.
              Під знижкою <Price currency="грн.">{promo0?.value || 0}</Price>{' '}
              мається на увазі сума зменшення рекомендованої клієнтської
              вартості автомобіля до спеціальної ціни за умови придбання у
              кредит за програмою «Renault Бонус». Ви можете отримати кредит за
              програмою «Renault Бонус» від Mobilize Financial Services для
              придбання автомобіля на наступних умовах: максимальний строк
              кредиту – 36 місяців, розмір першого внеску – від 30 % до 50 %
              (максимальний) від вартості автомобіля, максимальна сума кредиту –
              2 600 000 грн., процентна ставка – 14,9 % річних, комісія за
              видачу кредиту – 2,9 %, комісія за операції в Державному реєстрі
              обтяжень рухомого майна – 750 грн. Реальна річна процентна ставка
              становить 41,7 % річних (розрахована виходячи з максимальної суми
              кредиту та максимального строку користування кредитом). Кредитує
              АТ «КРЕДІ АГРІКОЛЬ БАНК». Ліцензія НБУ №99 від 12.10.2011.
              Реєстраційний № 149 у Державному реєстрі банків. Кредит надається
              у відділеннях Банку на всій території України крім тимчасово
              окупованих територій та територій, на яких державні органи України
              тимчасово не здійснюють свої повноваження (території, що
              перебувають в оточенні (блокуванні) та/або території на яких
              тривають активні бойові дії), внаслідок збройної агресії
              Російської федерації проти України розпочатої 24 лютого 2022 року.
              Не кредитуються області (якщо клієнт проживає та/або отримує дохід
              від бізнесу в цих областях): Сумську, Харківську, Луганську,
              Донецьку, Миколаївську, Херсонську, Запорізьку, АРК. З метою
              отримання інформації щодо оформлення кредиту звертайтеся за
              телефонами гарячої лінії 0800305555 або на сайті банку
              www.credit-agricole.ua. Банк залишає за собою право змінювати
              умови кредитування. Детальні умови кредитування на сайті
              www.credit-agricole.ua. Замовником реклами є АТ «КРЕДІ АГРІКОЛЬ
              БАНК». Для отримання кредиту згідно з програмою «Renault Бонус»
              від Mobilize Financial Services обов'язково придбання наступних
              послуг: страхування КАСКО та ОСЦПВ (надає СК «ARX»: Ліцензія
              Держфінпослуг серія АВ №483293 від 17.09.2009 р., Ліцензія
              Держфінпослуг серія АГ №569137 від 24.12.2010 р.; СК «УНІКА»:
              Ліцензія Держфінпослуг серія АЕ №293990 від 07.08.2014 р.,
              Ліцензія Держфінпослуг серія АЕ №293760 від 21.05.2014 р.; СК
              «Українська страхова група»: Ліцензія Держфінпослуг серія АГ №
              569295 від 21.01.2011 р., Ліцензія Держфінпослуг серія АВ №500320
              від 13.01.2010 р.; СК «ПЗУ УКРАЇНА»: Ліцензія Держфінпослуг серія
              АВ №500102 від 15.12.2009 р., Ліцензія Держфінпослуг серія АГ
              №569236 від 14.01.2011 р.) та страхування життя і здоров'я
              позичальника (надає СК «УНІКА»: Ліцензія Держфінпослуг серія АЕ
              №293990 від 07.08.2014 р., Ліцензія Держфінпослуг серія АЕ №293760
              від 21.05.2014 р.).
            </p>
          ) : (
            <p>
              *Спеціальна ціна за умовами придбання в кредит за програмою
              «Renault Бонус» від Mobilize Financial Services для фізичних осіб.
              Кредитні умови за програмою «Renault Бонус»: максимальний строк
              кредиту – 60 місяці, розмір першого внеску – від 20% до 50%
              (максимальний) від вартості автомобіля, максимальна сума кредиту –
              2 600 000 грн., процентна ставка – 12,9 % річних, комісія за
              видачу кредиту – 2,9 %, комісія за операції в Державному реєстрі
              обтяжень рухомого майна – 750 грн. Реальна річна процентна ставка
              становить 37,7 % річних (розрахована виходячи з максимальної суми
              кредиту та максимального строку користування кредитом). Кредит
              надається у відділеннях Банку на всій території України крім
              тимчасово окупованих територій та територій, на яких державні
              органи України тимчасово не здійснюють свої повноваження
              (території, що перебувають в оточенні (блокуванні) та/або
              території на яких тривають активні бойові дії), внаслідок збройної
              агресії Російської федерації проти України розпочатої 24 лютого
              2022 року. Не кредитуються області (якщо клієнт проживає та/або
              отримує дохід від бізнесу в цих областях): Луганську, Донецьку,
              Херсонську, Запорізьку, АРК. З метою отримання інформації щодо
              оформлення кредиту звертайтеся за телефонами гарячої лінії
              0800305555 або на сайті банку www.credit-agricole.ua. Кредитує АТ
              «КРЕДІ АГРІКОЛЬ БАНК». Ліцензія НБУ №99 від 12.10.2011.
              Реєстраційний № 149 у Державному реєстрі банків. Банк залишає за
              собою право змінювати умови кредитування. Детальні умови
              кредитування на сайті www.credit-agricole.ua. Замовником реклами
              кредитних послуг є АТ «КРЕДІ АГРІКОЛЬ БАНК». Для отримання кредиту
              згідно з програмою «Renault Бонус» обов'язково придбання наступних
              послуг: страхування КАСКО та ОСЦПВ (надає СК «ARX»: Ліцензія
              Держфінпослуг серія АВ №483293 від 17.09.2009 р., Ліцензія
              Держфінпослуг серія АГ №569137 від 24.12.2010 р.; СК «УНІКА»:
              Ліцензія Держфінпослуг серія АЕ №293990 від 07.08.2014 р.,
              Ліцензія Держфінпослуг серія АЕ №293760 від 21.05.2014 р.; СК
              «Українська страхова група»: Ліцензія Держфінпослуг серія АГ №
              569295 від 21.01.2011 р., Ліцензія Держфінпослуг серія АВ №500320
              від 13.01.2010 р.; СК «ПЗУ УКРАЇНА»: Ліцензія Держфінпослуг серія
              АВ №500102 від 15.12.2009 р., Ліцензія Держфінпослуг серія АГ
              №569236 від 14.01.2011 р.) та страхування життя і здоров'я
              позичальника (надає СК «УНІКА»: Ліцензія Держфінпослуг серія АЕ
              №293990 від 07.08.2014 р., Ліцензія Держфінпослуг серія АЕ №293760
              від 21.05.2014 р.)
            </p>
          )}
          <p>
            **Дана функція не є частиною комплектації або додатковим обладнанням
            автомобіля та означає можливість з’єднання з Android Auto/Apple Car
            Play при умові використання мобільних пристроїв за допомогою
            спеціальних мобільних додатків. Виробник та Продавець автомобіля не
            несуть відповідальності за можливі неполадки чи неточності в роботі
            мобільних додатків, зокрема за відсутність чи неправильну роботу в
            них певних сервісів, або за відсутність інтерфейсу на українській
            мові, у тому числі голосового управління, а також не відповідають за
            роботу мобільного пристрою користувача та технічні характеристики
            такого пристрою, які, зокрема, можуть обмежувати роботу та
            функціонал мобільних додатків, або ж обмежувати можливості
            користування даною функцією автомобіля.
          </p>
          <p>
            Терміни «На складі», «В дорозі», «Дилер» - означають
            місцезнаходження автомобіля станом на дату оновлення даних он-лайн
            складу, а саме: на складі імпортера в Україні, у процесі
            транспортування до складу імпортера, на складі у дилера, відповідно.
            Щодо автомобілів «На складі», «В дорозі» всі деталі замовлення та
            доставки можливо уточнювати в обраного споживачем дилера офіційної
            дилерської мереж Рено. Щодо деталей замовлення автомобілів зі
            статусом «Дилер» необхідно звертатись безпосередньо до вказаного
            дилера.
          </p>
          <p>
            Обране авто уже може бути зарезервованим за іншим клієнтом, оскільки
            інформація онлайн складу оновлюється не частіше, ніж один раз на
            добу. Будь-ласка, дізнайтесь наявність автомобіля та деталі у дилера
            Renault в Україні.
          </p>
          <p>
            Наведена інформація в онлайн складі може бути не остаточною і
            підлягає уточненню у дилера Renault в Україні.
          </p>
          <p>
            Гаряча лінія Renault:
            <a className="px-1" href="tel: 0 800 500 351">
              0-800-500-351
            </a>
            (усі дзвінки в межах України зі стаціонарних і мобільних номерів
            українських операторів зв’язку – безкоштовні). Перелік дилерів, що
            здійснюють свою діяльність, зазначено на сайті
            <a
              className="px-1"
              href="//www.renault.ua"
              target="_blank"
              rel="noreferrer"
            >
              www.renault.ua.
            </a>
            Режим роботи обраного дилерського центру необхідно уточнювати
            безпосередньо в такого дилера.
          </p>
        </>
      </Info>
      <Modal show={showForm !== null} onHide={() => setShowForm(null)}>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton className="text-center">
            <Modal.Title className="w-100">
              {showForm === 'info' && 'Залишити Запит'}
              {showForm === 'credit' && 'Отримати розрахунок кредиту'}
              {!result && (
                <div style={{ fontSize: 16 }}>
                  Автомобіль: <b>{versionName}</b>
                </div>
              )}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {result ? (
              <>
                <Table className={styles.modalTable} borderless hover>
                  <tbody>
                    <tr>
                      <td>Автомобіль</td>
                      <th>{versionName}</th>
                    </tr>
                    <tr>
                      <td>Дилер</td>
                      <th>
                        {
                          dealers?.find((d) => d.bir === formik.values.dealer)
                            ?.name
                        }
                      </th>
                    </tr>
                  </tbody>
                </Table>

                <div
                  style={{
                    backgroundColor: '#f2f2f2',
                    textAlign: 'center',
                    fontSize: 18,
                    padding: '40px 16px',
                    margin: '-16px',
                    marginTop: '16px',
                  }}
                >
                  Дякуємо за Ваше звернення, наш менеджер з Вами зв’яжеться
                  протягом 2х годин у робочі дні.
                </div>
              </>
            ) : (
              <>
                <RInput
                  type="text"
                  name="name"
                  label={`Ім'я`}
                  isRequired
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && !!formik.errors.name}
                  success={formik.touched.name && !formik.errors.name}
                  errMessage={formik.errors.name}
                />

                <RInput
                  name="phone"
                  as={IMaskInput}
                  type="text"
                  label="Контактний телефон"
                  isRequired
                  mask={'+38 (000) 000-00-00'}
                  placeholder="Введіть номер телефону"
                  value={formik.values.phone}
                  onAccept={(value: any, mask: any) => {
                    formik.setFieldValue('phone', value);
                  }}
                  error={formik.touched.phone && !!formik.errors.phone}
                  success={formik.touched.phone && !formik.errors.phone}
                  errMessage={formik.errors.phone}
                />

                <RenaultSelect
                  name="dealer"
                  searchable
                  data={
                    dealers
                      ?.filter((d) => !FakeBirs.includes(d.bir))
                      .map(({ name, bir }) => ({
                        label: name,
                        value: bir,
                      })) || []
                  }
                  value={formik.values.dealer}
                  onChange={(bir) => {
                    formik.setFieldValue('dealer', bir || '');
                  }}
                  placeholder="Оберіть дилера"
                  className="my-2 mt-3"
                  error={formik.touched.dealer && !!formik.errors.dealer}
                />

                <BsForm.Control
                  name="comment"
                  as="textarea"
                  placeholder="Коментар"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                  className={styles.textarea}
                />

                <BsForm.Check
                  type="checkbox"
                  name="sms"
                  id="sms"
                  checked={formik.values.sms}
                  onChange={formik.handleChange}
                  label={`отримувати новини та акції бренду Renault по смс/viber`}
                  className="mt-3 mb-4"
                />

                <div style={{ fontSize: 13 }}>
                  "Натискаючи кнопку «Підтвердити», Ви надаєте згоду на обробку
                  наданих Вами персональних даних.{` `}
                  <a
                    style={{ textDecoration: 'underline' }}
                    href="https://www.renault.ua/personal-data.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Детальніше про обробку Ваших персональних даних
                  </a>
                  "
                </div>

                <Button
                  disabled={formik.isSubmitting}
                  type="submit"
                  variant="super-primary"
                  className="mt-3"
                  style={{
                    textTransform: 'uppercase',
                    display: 'block',
                    width: '100%',
                    padding: '12px 10px',
                  }}
                >
                  {formik.isSubmitting ? (
                    <Spinner animation="border" role="status"></Spinner>
                  ) : (
                    <>
                      {showForm === 'info' && 'Відправити запит'}
                      {showForm === 'credit' && 'Запит на розрахунок кредиту'}
                    </>
                  )}
                </Button>
              </>
            )}
          </Modal.Body>
        </form>
      </Modal>
      {!isDealerSite && <Footer />}
    </>
  );
}

export default CarDetail;

// fetch('https://car-warehouse.rnlt.pp.ua/api/cars/list')
//   .then(async (res) => await res.json())
//   .then(({ data }) =>
//     console.log(new Set(data.map((c) => c.version.versionName)))
//   );
