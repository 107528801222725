import { RxDotFilled } from 'react-icons/rx';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useSearchParams } from 'react-router-dom';
// import placeholder from '../static/images/car-default.png';
import { Button } from './button';

import { getBaseCarImg, getCarImg, getPrice, isFakeDealer } from '../helpers';
import { IDealer, IModel } from '../types';
import styles from './car-card.module.scss';
import { Price } from './price';

interface ICarShortCardProps {
  colorsAmount: number;
  carsAmount: number;
  modelCode: string;
  modelName: string;
  minPrice: number;
}

export function CarShortCard({
  carsAmount,
  colorsAmount,
  modelCode,
  modelName,
  minPrice,
}: ICarShortCardProps) {
  const [searchParams] = useSearchParams();
  const selCity = searchParams.get('city') ?? '';
  const img = getBaseCarImg(modelCode as any);

  const colorText = (amount: number) => {
    if (amount === 1) return `${amount} колір`;
    if (amount > 1 && amount < 5) return `${amount} кольори`;
    if (amount > 19) {
      if (`${amount}`.endsWith('1')) return `${amount} колір`;
      if (['2', '3', '4'].includes(`${amount}`.at(-1) || ''))
        return `${amount} кольори`;
    }
    return `${amount} кольорів`;
  };

  return (
    <Link to={`/${modelCode}${selCity ? `?city=${selCity}` : ''}`}>
      <div className={`${styles.car} p-1`}>
        <img src={img} className={styles.carImg} alt={modelName} />
        <div className={styles.title}>{modelName}</div>
        <div className={styles.info} style={{ fontSize: 15 }}>
          {carsAmount} авто <RxDotFilled />
          {` `}
          {colorText(colorsAmount)}
        </div>
        <div className="mt-3">
          <div className={styles.flipBox}>
            <div className={styles.inner}>
              <div className={styles.front}>
                <span className={styles.price}>
                  {!minPrice || minPrice === Infinity ? (
                    `уточнюйте у дилера`
                  ) : (
                    <>
                      від{` `}
                      <Price
                        currency={
                          ['R3U', 'R3O', 'DF1'].includes(modelCode)
                            ? 'грн**'
                            : 'грн*'
                        }
                      >
                        {minPrice}
                      </Price>
                    </>
                  )}
                </span>
              </div>
              <div className={styles.back}>
                <Button>детальніше</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

interface ICarFullCardProps {
  car: IModel;
}

export function CarFullCard({ car }: ICarFullCardProps) {
  const {
    vin,
    version: {
      engineCapacity,
      gearboxType,
      fuelType,
      version: versionCode,
      equipmentName,
    },
    model: { code: modelCode },
    modelName,
    discount,
    dealer,
    color,
  } = car;
  const img = getCarImg(modelCode as any, equipmentName, color, versionCode);
  const getAddress = () => {
    if (isFakeDealer(dealer)) return dealer.name;
    return dealer.address.split(',')[0];
  };

  const price = getPrice(car);

  return (
    <Link to={`/${modelCode}/${vin}`}>
      <div className={`${styles.car} p-1`}>
        <div className="position-relative">
          {discount && (
            <span className={`${styles.discountLabel} isDiscount`}>акція</span>
          )}
          <img src={img} className={styles.carImg} alt={modelName} />
        </div>
        <div className={styles.info}>
          {(Number(engineCapacity) / 1000).toFixed(1)} <RxDotFilled />
          {gearboxType}
          <RxDotFilled /> {fuelType}
        </div>
        <div className={styles.location}>{getAddress()}</div>
        <div className="mt-4">
          <div className={styles.flipBox}>
            <div className={styles.inner}>
              <div className={styles.front}>
                <span className={styles.price}>
                  {price ? <Price>{price}</Price> : <>уточнюйте у дилера</>}
                </span>
              </div>
              <div className={styles.back}>
                <Button>детальніше</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
