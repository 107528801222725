import { CarImages, FakeBirs, SedreStatus } from './constants';
import { EModelCode, IDealer, IFakeDealer, IModel } from './types';
import { IEqpGroup } from './types/equipments';

export const isFakeDealer = (dealer: IDealer): dealer is IFakeDealer => {
  return FakeBirs.includes(dealer.bir);
};

export const isSedreTypeEqual = (
  type: keyof typeof SedreStatus,
  code?: string
) => {
  if (!code) return false;
  return SedreStatus[type].includes(code);
};

// step 1
export const getBaseCarImg = (modelCode: keyof typeof EModelCode): string => {
  const imgPath = '/assets/images/models/';
  const imgDefault = `car-default.png`;

  if (CarImages[modelCode]?.img) {
    return imgPath + `${modelCode}/` + CarImages[modelCode].img;
  }

  return imgPath + imgDefault;
};

const checkVersion = (version: string, versions?: string[]) => {
  if (!versions?.length) return true;
  return versions.includes(version);
};

// step 2
export const getCarImg = (
  modelCode: keyof typeof EModelCode,
  equipmentName: string,
  color: string,
  version: string
): string => {
  const imgPath = '/assets/images/models/';
  const imgDefault = `car-default.png`;

  const eqp = CarImages[modelCode]?.ext.find((i) => {
    // i.eqp.includes(equipmentName) && checkVersion(version, i.versions);
    if (i.filters?.eqp) {
      if (!i.filters.eqp.includes(equipmentName)) return false;
    }
    if (i.filters?.versions) {
      if (!i.filters.versions.includes(version)) return false;
    }
    if (i.filters?.colors) {
      if (!i.filters.colors.includes(color)) return false;
    }

    return true;
  });
  const eqpAndColor = eqp && eqp.colors && eqp.colors[color]?.base;
  if (eqpAndColor) return imgPath + `${modelCode}/` + eqpAndColor;

  const firstColor = eqp && eqp?.colors && Object.values(eqp?.colors)[0];
  if (firstColor) {
    // return first color in equipment if exist
    return imgPath + `${modelCode}/` + firstColor.base;
  } else {
    const modelImg = CarImages[modelCode]?.img;
    if (modelImg) return imgPath + `${modelCode}/` + modelImg;

    return imgPath + imgDefault;
  }
};

// step 3
export const getCarImgList = (
  modelCode: keyof typeof EModelCode,
  equipmentName: string,
  color: string,
  version: string,
  options: IEqpGroup[] | undefined
): string[] => {
  const imgPath = '/assets/images/models/';
  const imgDefault = `car-default.png`;

  const eqp = CarImages[modelCode]?.ext?.filter((i) => {
    // i.eqp.includes(equipmentName) && checkVersion(version, i.versions)
    if (i.filters?.eqp) {
      if (!i.filters.eqp.includes(equipmentName)) return false;
    }
    if (i.filters?.versions) {
      if (!i.filters.versions.includes(version)) return false;
    }
    if (i.filters?.colors) {
      if (!i.filters.colors.includes(color)) return false;
    }

    return true;
  });

  const optionCodes = options
    ?.map((o) => o.equipments.map((e) => e.optionCode))
    ?.flat();
  const byOpt: string[] = eqp
    .map((o) => {
      return (o?.byOptions || []).map(({ base, list }) => {
        const found = list.find(({ code }) => {
          return optionCodes?.includes(code);
        });

        return found?.img || base || null;
      });
    })
    .flat()
    .filter(Boolean)
    .map((name) => imgPath + `${modelCode}/` + name);

  const common = eqp
    .map((o) => {
      return (o?.imgList || []).map((name) => name);
    })
    .flat()
    .map((name) => imgPath + `${modelCode}/` + name);

  const eqpAndColor =
    eqp && eqp.map((o) => (o.colors ? o.colors[color]?.list || [] : [])).flat();
  if (eqpAndColor?.length)
    return [
      ...eqpAndColor.map((name) => imgPath + `${modelCode}/` + name),
      ...byOpt,
      ...common,
    ];

  // DF1 - EXPRESS VAN // because the main image depends on the option code
  if (modelCode === 'DF1') {
    return [...byOpt, ...common];
  }

  const baseEqpColor = eqp.find(
    (o) => o.colors && o.colors[Object.keys(o.colors)[0]].base
  );
  if (baseEqpColor?.colors) {
    const { colors } = baseEqpColor;
    const color = colors[Object.keys(colors)[0]].base;
    return [imgPath + `${modelCode}/` + color, ...byOpt, ...common];
  } else {
    const modelImg = CarImages[modelCode]?.img;
    if (modelImg)
      return [imgPath + `${modelCode}/` + modelImg, ...byOpt, ...common];

    return [imgPath + (modelImg || imgDefault), ...byOpt, ...common];
  }
};

export const isPresent = <T>(t: T | undefined | null): t is T => !!t;
export const isDefined = <T>(t: T | undefined): t is T => !!t;
export const isFilled = <T>(t: T | null): t is T => !!t;

export const getPrice = (car: IModel) => {
  const {
    model: { promo },
    discount,
    price: basePrice,
  } = car;
  const promo0 = Number(promo?.find(({ type }) => type === '0')?.value) || 0;
  const price = Number(basePrice) || 0;
  const discountPrice = Number(discount?.price) || 0;
  return (discountPrice || price) - promo0;
};

export const isPC = (m: Pick<IModel['model'], 'code' | 'segment'>) =>
  m.segment === 'PC' && !['DF1', 'DK1'].includes(m.code);
export const isLCV = (m: Pick<IModel['model'], 'code' | 'segment'>) =>
  m.segment === 'LCV' || ['DF1', 'DK1'].includes(m.code);
