import { PropsWithChildren } from 'react';

import { IconType } from 'react-icons';
import styles from './button.module.scss';

interface IButtonProps {
  variant?:
    | 'super-primary'
    | 'primary'
    | 'secondary'
    | 'secondary-text'
    | 'secondary-inv'
    | 'primary-link'
    | 'alt-link'
    | 'var-1';
  children?: any;
  active?: boolean;
  leftIcon?: IconType;
}

export function Button({
  variant = 'secondary',
  children,
  active = false,
  leftIcon: LeftIcon,
  className,
  ...props
}: PropsWithChildren<
  IButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>) {
  const classes = [styles[variant], active && styles.active, className]
    .filter(Boolean)
    .join(' ');

  return (
    <button className={classes} {...props}>
      {LeftIcon && <LeftIcon className={children ? 'me-2' : ''} />}
      {children}
    </button>
  );
}
